export const MessageAnalytics = (
  $analyticsManager,
  $q,
  analyticsDateFilter
) => {
  function _MessageAnalytics(message) {
    var analytics = { message: message, name: "messageAnalytics" };

    analytics.resolve = function(period) {
      return $q(function(resolve, reject) {
        const period_start = angular.copy(
          analyticsDateFilter(period.start, "start")
        );
        const period_end = angular.copy(analyticsDateFilter(period.end, "end"));

        var promise = $analyticsManager.request({
          method: "message",
          id: message.id,
          params: { period_start, period_end, live_mode: message.live_mode }
        });
        promise.then(
          function(response) {
            var value = response ? response.data : 0;
            return resolve(value);
          },
          function(reason) {
            reject(reason);
          }
        );
      });
    };
    return analytics;
  }

  return _MessageAnalytics;
};

MessageAnalytics.$inject = ["$analyticsManager", "$q", "analyticsDateFilter"];

import "./emailEditor.scss";
import "./codemirror-theme.scss";

const { clearAllBodyScrollLocks } = bodyScrollLock;

const contentStyle = `

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
}
p {
  line-height: 150%;
  margin: 0 0 21px 0;
  font-size: 18px;
}

a.as-button {
  background-color: #0f87f0;
  padding: 0 60px;
  min-width: 200px;
  height: 57px;
  text-align: center;
  line-height: 57px;
  border-radius: 4px;
  display: block;
  width: max-content;
  font-size: 20px;
  text-decoration: none;
  color: #fff;
  margin: 15px 0 33px 0;
}

h1 {
  font-family: 'Proxima Nova Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0 0 33px;
  font-size: 36px;
  line-height: 130%;
  font-weight: normal;
}

h2 {
  font-family: 'Proxima Nova Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0 0 21px;
  font-size: 23px;
  line-height: 130%;
  font-weight: normal;
}

a.as-button:visited {
  color: #fff;
}


body#tinymce {
  margin: 19px 17px;
}
`

tinyMCE.PluginManager.add('stylebuttons', function(editor) {
  ['pre', 'p', 'code', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].forEach(function(name){
   editor.addButton("style-" + name, {
       tooltip: "Toggle " + name,
         text: name.toUpperCase(),
         disabledStateSelector: 'a.as-button',
         onClick: function() { editor.execCommand('mceToggleFormat', false, name); },
         onPostRender: function() {
             var self = this, setup = function() {
                 editor.formatter.formatChanged(name, function(state) {
                     self.active(state);
                 });
             };
             editor.formatter ? setup() : editor.on('init', setup);
         }
     })
  });
});

import "../../../css/skins/lightgray/skin.min.css";
import 'tinymce/themes/modern/theme';


class _Controller {

  constructor ($message, $timeout, $emailDesign, $modal, $testSendModal) {
    this.$message = $message;
    this.$timeout = $timeout;
    this.isDirty = false;
    this.$emailDesign = $emailDesign;
    this.$modal = $modal;
    this.$testSendModal = $testSendModal;
    this.tinymceOptions = {
      plugins: 'textcolor colorpicker customLink code button stylebuttons palette code',
      menubar: false,
      toolbar: 'undo redo | bold italic | link | button | style-h1 style-h2 | removeformat palette | code',
      height: 400,
      statusbar: false,
      content_style: `${contentStyle} ${this.buildDynamicEditorStyles()}`,
      skin: false,
      convert_urls: false,
      relative_urls: false,
      remove_script_host: false,
    };
    // this.isDirty = !this.$message.emailHasSavedContent;

    this.codeEditorOptions = {
      lineWrapping : false,
      lineNumbers: true,
      mode: 'htmlmixed',
      theme: 'monokai',
      tabSize: 2,
      autoRefresh: true
    };

  }

  $onInit() {
    this.$modal.new('.design-modal-show');
    this.$timeout(() => {
      this.body = this.$message.email.body;
    })
  }

  $onDestroy() {
    clearAllBodyScrollLocks();
  }

  close() {
    if (!this.isDirty) return this.onClose();
    if (confirm('Your unsaved changes will be lost. Are you sure?')) {
      this.onClose()
    }
  }

  onChange() {
    this.isDirty = true;
  }

  save() {
    this.$message.email.body = this.body;
    this.$message.email.save().then(() => {
      // this.$message.emailHasSavedContent = true;
      this.isDirty = false;
    });
  }

  showTestSend() {
    this.$testSendModal.new();
  }

  hide() {
    this.tinymceOptions.content_style = `${contentStyle} ${this.buildDynamicEditorStyles()}`;
    this.refreshing = true;
    this.$timeout(() => { this.refreshing = false; })
    this.$modal.hide();
  }

  buildDynamicEditorStyles() {
    return `
      a.as-button {
        background-color: ${this.$emailDesign.buttonColor};
      }

      a.as-button[data-mce-selected="inline-boundary"] {
        background-color: ${this.$emailDesign.buttonColor}!important;
      }

      a:not(.as-button) {
        color: ${this.$emailDesign.linkColor};
      }

      h1 {
        color: ${this.$emailDesign.h1Color};
      }
      
      h2 {
        color: ${this.$emailDesign.h2Color};
      }

      p {
        color: ${this.$emailDesign.textColor};
      }
    `;
  }

}

_Controller.$inject = ['$message', '$timeout', '$emailDesign', '$modal', '$testSendModal'];

export const emailEditor = {
  controller: _Controller,
  template: require('./emailEditor.html'),
  bindings: {
    onClose: '&',
    bodyType: '<'
  },
};
import "./scheduled.scss";

class _Controller {
  constructor($resourceManager, $message, $scheduledStore, $timeout) {
    this.$resourceManager = $resourceManager;
    this.$message = $message;
    this.$timeout = $timeout;
    this.$a = $scheduledStore;
  }
}

_Controller.$inject = [
  "$resourceManager",
  "$message",
  "$scheduledStore",
  "$timeout",
];

export const scheduled = {
  controller: _Controller,
  template: require("./scheduled.html"),
  bindings: {
    messageId: "<",
  },
};

export class _Controller {

  constructor ($resourceManager, UserService, $modal) {
    this.$resourceManager = $resourceManager;
    this.customer = null;
    $modal.new('.modal-backdrop-new');
    this.$modal = $modal;
    this.subscription = null;
    this.user = UserService.user;
    this.loaded = false;
  }

  $onInit() {
    this.trialExpired = this.calculateDaysUntilExpiry(this.user) < 0;

    this.$resourceManager.request('users', 'customer', {id: 'current'}).then(response => {
      this.customer = (response.id) ? response : false;
    });
    
    this.$resourceManager.request('users', 'subscription', {id: 'current'}).then(response => {
      this.subscription = (response.id) ? response : false;
      this.loaded = true;
      if (!response.id) this.subscriptionCanceled = true;
    });

  }

  calculateDaysUntilExpiry() {
    if (!this.subscription) return false;
    var endDate = this.subscription.trial_end * 1000;
    return parseInt((endDate - Date.now()) / (24 * 3600 * 1000));
  }

  renewSubscription() {
    this.$resourceManager.request('users', 'renew_subscription', {id: 'current'}).then(() =>{
      window.location = window.location;
    });
  }

  uncancelSubscription() {
    this.$resourceManager.request('users', 'uncancel_subscription', {id: 'current'}).then(() =>{
      window.location = window.location;
    });
  }

  cancelNewSubscription(event) {
    if (event) event.preventDefault();
    this.$modal.hide()
  }

  newSubscription() {
    this.$modal.show();
  }

}

_Controller.$inject = ['$resourceManager', 'UserService', '$modal'];

export const accountSubscription = {
  controller: _Controller,
  template: require('./accountSubscription.html')
};
function analyticsRateController () {
  this.reset = function () {
    this.valueA = null;
    this.valueB = null;
    this.value = null;
    this.loading = false;
  };

  this.reset();

  this.resolveValues = function (period) {
    this.loading = true;
    this.service.resolve(period).then(function (response) {
      this.loading = false;
      this.valueA = response[this.typeA];
      this.valueB = response[this.typeB];
      this.value = this.valueA / this.valueB;
    }.bind(this), function (reason) {
      this.reset();
      this.error = true;
    }.bind(this));
  };
}

export const analyticsRate = () => {
  return {
    restrict: 'E',
    require: ['^analytics'],
    scope: {},
    template: require('./rate.html'),
    bindToController:{ typeA:'@', typeB: '@', label:'@', unit: '@', period: '=', target: '=' },
    controller:analyticsRateController,
    controllerAs:'ctrl',
    link: function(scope, element, attrs, ctrls){
      var analytics = ctrls[0];
      scope.ctrl.service = analytics.service();
      scope.ctrl.resolveValues(scope.ctrl.period);
      scope.currency = analytics._currency;

      scope.$watch('ctrl.period', function (newV, oldV) {
        if (oldV != newV) scope.ctrl.resolveValues(scope.ctrl.period);
      }, true);
    }
  };
};
class _Controller {

  constructor ($resourceManager, UserService, $timeout, $testSendModal) {
    this.$resourceManager = $resourceManager;
    this.clientId = null;
    this.processing = true;
    this.user = UserService.user;
    this.$timeout = $timeout;
    this.showDesktop = false;
    this.$testSendModal = $testSendModal;
    this.showMobile = true;
    this.isMobile = true;
  }
  
  $onInit() {
    this.loadPreview();
  }

  $onChanges() {
    this.loadPreview();
  }

  doShowDesktop() {
    this.showMobile = false;
    this.$timeout(() => {
      this.showDesktop = true;
    }, 200)
  }

  doShowMobile() {
    this.showDesktop = false;
    this.$timeout(() => {
      this.showMobile = true;
    }, 200)
  }

  updateIsMobile() {
    if (this.isMobile) {
      this.doShowMobile();
    } else {
      this.doShowDesktop();
    }
  }

  fixMobileCss(html) {
    const [ before, after ] = html.split("<style>");
    return (before + "<style>html body[style] { transform: scale(0.63); transform-origin: 0 0; }" + after).replace(/(<body.*style=".*; width: )(100% !important;)/, "$1360px !important;");
  }

  loadPreview() {
    if (this.body) {
      if (this.clientId === "") this.clientId = null;
      let payload = {subject: this.subject, body: this.body, body_type: this.body_type, customer_id: this.clientId};
      this.$resourceManager.request('emails', 'preview', null, payload).then(response => {
        this.processing = false;
        this.updateIframe('desktop-preview', response.html);
        this.updateIframe('mobile-preview', this.fixMobileCss(response.html));
      });
    }
  }

  updateIframe(iframeId, html) {
    var iframe = document.getElementById(iframeId);
    var doc = iframe.contentWindow.document;
    doc.documentElement.innerHTML = html;
  }
}

_Controller.$inject = ['$resourceManager', 'UserService', '$timeout', '$testSendModal'];

export const preview = {
  controller: _Controller,
  template: require('./preview.html'),
  bindings: {
    body: '<',
    body_type: '<bodyType',
    subject: '<'
  },
};
const { enableBodyScroll, disableBodyScroll, clearAllBodyScrollLocks } = bodyScrollLock;

export const ScrollLock = function($timeout) {

  function _ScrollLock(targetElementSelector) {

    const api = {
      targetElementSelector: targetElementSelector,
      targetElement: null,
    };

    api.on = () => {
      api.targetElement = document.querySelector(api.targetElementSelector);
      if (!api.targetElement) {
        return $timeout(() => api.on(), 150);
      }
      disableBodyScroll(api.targetElement);
    }

    api.off = event => {
      if (event) {
        let targetClass = event.target.classList.value;
        if (!["dismiss-standard"].includes(targetClass)) return false;
      }
      enableBodyScroll(api.targetElement);
    };

    api.clear = () => {
      clearAllBodyScrollLocks();
    }

    return api;
  }

  return _ScrollLock;

};

ScrollLock.$inject = ['$timeout'];
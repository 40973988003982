export const timeTargetingSummary = function($targetingOptions, capitalizeFilter) {
  return function(targeting, opportunity_type) {
    var units = $targetingOptions.getSelectedOptionText(opportunity_type, targeting);
    var targetingDescription = $targetingOptions.getDescription(opportunity_type);

    return "<strong>" + capitalizeFilter(units) + "</strong> " + targetingDescription;
  };
};

timeTargetingSummary.$inject = ['$targetingOptions', 'capitalizeFilter'];

import { asyncButton } from "./async_button/asyncButton.component";
import { modal } from "./modal/modal.component";
import { fullPage } from "./full_page/fullPage.component";
import { textField } from "./text_field/textField.component";
import { $modal } from "./modal/modal.service";
import { stripeCardField } from "./stripe_card_field/stripeCardField.component";

export const ELEMENTS_MODULE = angular.module('elementsModule', []);

ELEMENTS_MODULE.component('asyncButton', asyncButton);
ELEMENTS_MODULE.component('stripeCardField', stripeCardField);
ELEMENTS_MODULE.component('modal', modal);
ELEMENTS_MODULE.component('textField', textField);
ELEMENTS_MODULE.service('$modal', $modal);
ELEMENTS_MODULE.component('fullPage', fullPage);
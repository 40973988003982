export const FILTERS_MODULE = angular.module("filters", []);

import { bigNumber } from "./bigNumber.filter";
import { opportunityType } from "./opportunityType.filter";
import { capitalize } from "./capitalize.filter";
import { currencyDisplay } from "./currencyDisplay.filter";
import { desnake } from "./desnake.filter";
import { eventType } from "./eventType.filter";
import { gradedColorClass } from "./gradedColorClass.filter";
import { momentTime, momentFormat } from "./momentTime.filter";
import { objectFilter } from "./objectFilter.filter";
import { perCent } from "./perCent.filter";
import { targetingSummary } from "./targetingSummary.filter";
import { pluralize } from "./pluralize.filter";
import { shortTimeTargetingSummary } from "./shortTimeTargetingSummary.filter";
import { spinalCase } from "./spinalCase.filter";
import { timeTargetingSummary } from "./timeTargetingSummary.filter";
import { truncate } from "./truncate.filter";
import { sender } from "./sender.filter";
import { analyticsDate } from "./analyticsDate.filter";
import { indefiniteArticle } from "./indefiniteArticle.filter";

FILTERS_MODULE.filter("bigNumber", bigNumber);
FILTERS_MODULE.filter("capitalize", capitalize);
FILTERS_MODULE.filter("currencyDisplay", currencyDisplay);
FILTERS_MODULE.filter("desnake", desnake);
FILTERS_MODULE.filter("analyticsDate", analyticsDate);
FILTERS_MODULE.filter("eventType", eventType);
FILTERS_MODULE.filter("gradedColorClass", gradedColorClass);
FILTERS_MODULE.filter("momentTime", momentTime);
FILTERS_MODULE.filter("momentFormat", momentFormat);
FILTERS_MODULE.filter("objectFilter", objectFilter);
FILTERS_MODULE.filter("perCent", perCent);
FILTERS_MODULE.filter("targetingSummary", targetingSummary);
FILTERS_MODULE.filter("pluralize", pluralize);
FILTERS_MODULE.filter("shortTimeTargetingSummary", shortTimeTargetingSummary);
FILTERS_MODULE.filter("spinalCase", spinalCase);
FILTERS_MODULE.filter("timeTargetingSummary", timeTargetingSummary);
FILTERS_MODULE.filter("truncate", truncate);
FILTERS_MODULE.filter("sender", sender);
FILTERS_MODULE.filter("opportunityType", opportunityType);
FILTERS_MODULE.filter("indefiniteArticle", indefiniteArticle);

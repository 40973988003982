export const dots = ($document, $timeout, $rootScope) => {
  return {
    restrict: 'E',
    transclude: true,
    replace: true,
    scope: {
      extraClass: '@',
      disabled: '=',
      label: '@',
      name: '@'
    },
    template: require('./dots.html'),
    link: function (scope) {
      scope.isOpen = false;

      scope.open = function () {
        scope.isOpen = true;
        $rootScope.$emit(scope.name + ":open");
        $timeout(function(){
          $document.one("click", scope.close);
        }, 10);
      };

      scope.toggle = function () {
        (scope.isOpen) ? scope.close() : scope.open();
      };

      scope.close = function () {
        $timeout(function(){
          scope.isOpen = false;
        });
        $rootScope.$emit("reset:" + scope.name + ":open");
      };
    }
  };
};

dots.$inject = ['$document', '$timeout', '$rootScope'];
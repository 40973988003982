const config = {
  headers: { 'X-HTTP-Method-Override': 'POST' }
};

class SignUpController {
  constructor(Auth, $window, $location, userErrors, $timeout) {
    this.errors = userErrors({doValidate: ['email', 'password']});
    this.Auth = Auth;
    this.$window = $window;
    this.$timeout = $timeout;
    this.$location = $location;
    this.signUp = {};
    this.submitted = false;
    this.user = {
      email: null,
      password: null
    };
  }

  onSuccess() {
    this.complete = true;
    this.$timeout(() => {
      this.$location.path('/dashboard').search({signup: "true"});
    }, 1500);
  }

  submit() {

    this.submitted = true;

    this.errors.reset();

    if (!this.errors.any(this.user)) {
      this.submitted = false;
      return this.errors.notify();
    }

    let user = this.user;
    user.password_confirmation = user.password;

    this.Auth.register(user, config)
      .then(this.onSuccess.bind(this))
      .catch(this.errors.notify.bind(this))
      .finally(() => this.submitted = false);
  }

}

SignUpController.$inject = ['Auth', '$window', '$location', 'userErrors', '$timeout'];

export const signUp = {
  controller: SignUpController,
  template: require('./signUp.html')
};
export class _Controller {
  constructor($resourceManager) {
    this.$resourceManager = $resourceManager;
    this.loaded = false;
    this.invoices = null;
  }

  $onInit() {
    this.$resourceManager
      .request("users", "documents", { id: "current" })
      .then(({ invoices }) => {
        this.invoices = invoices;
        this.loaded = true;
      });
  }
}
_Controller.$inject = ["$resourceManager"];

export const accountDocuments = {
  controller: _Controller,
  template: require("./accountDocuments.html")
};

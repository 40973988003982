export const $testSendModal = () => {
  let modal = {
    show: false
  };

  modal.new = () => {
    modal.show = true;
  };

  modal.hide = () => {
    modal.show = false;
  };

  return modal;

};
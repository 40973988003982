import "./triggerSelector.scss";

class _Controller {
  constructor($opportunitiesAvailable, $message) {
    this.$opportunitiesAvailable = $opportunitiesAvailable;
    this.$message = $message;
    this.expanded = false;
    this.currentOpportunity = null;
  }

  showMenu(e) {
    this.expanded = true;
  }

  setCurrentOpportunity(opportunity) {
    this.currentOpportunity = opportunity;
  }

  hideMenu() {
    this.expanded = false;
    this.currentOpportunity = null;
  }

  setOpportunityType(opportunity) {
    if (this.$message.message.state === "started") {
      alert("Pause this automation if you'd like to edit the trigger.");
      this.hideMenu();
      return false;
    }
    const oldOpp = this.$message.message.opportunity_type;
    if (oldOpp != opportunity.id) {
      this.$message.setOpportunityType(opportunity.id);
      this.onChange();
    }
    this.hideMenu();
  }

  updateProducts() {}
}

_Controller.$inject = ["$opportunitiesAvailable", "$message"];

export const triggerSelector = {
  controller: _Controller,
  template: require("./triggerSelector.html"),
  bindings: {
    onChange: "&"
  }
};

const formatDate = (momentObject, time) => {
  return momentObject.format("YYYY-MM-DD " + time);
};

export const analyticsDate = function() {
  return function(input, anchor) {
    if (!input || !["start", "end"].includes(anchor)) return input;
    const time = (anchor === "start") ? "00:00:00" : "23:59:59"
    return formatDate(input, time);
  };
};
export const $opportunitiesStatus = ($http, $q, $opportunitiesAvailable) => {
  var opStatus = {};

  opStatus.shouldRunAgain = function (responseData) {
    return !!responseData.data;
  };

  opStatus.status = function () {
    var url = '/api/opportunities/status';
    return $q(function (resolve) {
      $http.get(url).then(function (result) {
        resolve(result);
      });
    });
  };

  return opStatus;
};

$opportunitiesStatus.$inject = ['$http', '$q', '$opportunitiesAvailable'];
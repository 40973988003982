import "./targetingPreview.scss";

class _Controller {
  constructor($resourceManager, $message, $cableConnection, $q) {
    this.opportunitiesLoading = null;
    this.loadingTimeout = null;

    this.$resourceManager = $resourceManager;
    this.$message = $message;

    this.requestId = Date.now();
    this.opportunities = [];
    this.processing = [];

    this.totalProcessCount = 0;
    this.completeProcessCount = 0;

    $cableConnection.registerFunction((message) => {
      this.onReceiveMessage(message)
    });
  }

  calculateSends() {
    this.opportunitiesLoading = true;
    this.opportunities = [];
    this.processing = [];

    this.totalProcessCount = 0;
    this.completeProcessCount = 0;
    this.getOpportunities();
  }

  percentageComplete() {
    return ($ctrl.completeProcessCount / $ctrl.totalProcessCount) * 100
  }

  getOpportunities() {
    const { id, settings, opportunity_type } = this.$message.message;
    this.$resourceManager.request('messages', 'targeting_preview', null, {id, settings, opportunity_type, request_id: this.requestId} )
    .then(response => {
      this.$message.setTargetingClean();
    });
  }

  stopLoading() {
    clearTimeout(this.loadingTimeout);
    this.loadingTimeout = setTimeout((_this) => {
      _this.opportunitiesLoading = false
    }, 5000, this);
  }

  onReceiveMessage(message = {}) {
    const { request_id: requestId, data } = message;
    if (requestId === this.requestId) {
      if (data.start) {
        this.processing.push(data.start);
        this.totalProcessCount = this.totalProcessCount + 1;
      } else if (data.end || data.no_data) {
        if (this.opportunitiesLoading) {
          this.stopLoading();
        }
        this.processing = this.processing.filter((i) => (i !== data.end));
        this.completeProcessCount = this.completeProcessCount + 1;
      } else {
        if (this.opportunitiesLoading) {
          this.stopLoading();
        }
        this.opportunities.push(data);
      }
    }
  }
}

_Controller.$inject = ['$resourceManager', '$message', '$cableConnection', '$q'];

export const targetingPreview = {
  controller: _Controller,
  template: require('./targetingPreview.html')
}

import "./modal.scss";
const { clearAllBodyScrollLocks } = bodyScrollLock;

class _Controller {
  close(event) {
    const classValue = event.target.classList.value;
    if (["dismiss-standard", "modal-holder-new"].includes(classValue)) {
      this.onClose();
    }
  }

  $onDestroy() {
    clearAllBodyScrollLocks();
  }
}

export const modal = {
  template: `
    <div class='modal-backdrop-new fade-in {{$ctrl.modalName}}' ng-show='$ctrl.show'>
      <div class='inner-backdrop white'></div>
      <div class='modal-holder-new ng-click='$ctrl.close($event)'>
        <div class='modal-new fade-down' ng-show='$ctrl.show'>
          <button class='dismiss-standard' ng-click='$ctrl.close($event)'>Close</button>
          <div ng-transclude></div>
        </div>
      </div>
    </div>
  `,
  bindings: {
    show: '<',
    onClose: '&',
    modalName: '@'
  },
  transclude: true,
  controller: _Controller
}
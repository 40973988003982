export const objectFilter = function() {
  return function(input, search) {
    if (!input) return input;
    if (!search) return input;
    var keyLength = Object.keys(search).length;
    if (keyLength !== 1) {
      console.error('objectFilter accepts one key-value pair as search param. ' + keyLength + ' were provided.');
      return input;
    }

    var result = {};
    angular.forEach(input, function(value, key) {
      for (var searchKey in search) {
        var searchValue = search[searchKey];
        if (value[searchKey] == searchValue) {
          result[key] = value;
        }
      }
    });
    return result;
  };
};

// objectFilter.$inject = [];

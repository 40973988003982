export const $analyticsResource = ($http, $q) => {
  var analytics = {
    data: {}
  };

  function request (url, params, cache) {
    cache = cache || false;
    return $q(function (resolve, reject) {
      var innerPromise = $http.get(url, {cache: cache, params: params, paramSerializer: '$httpParamSerializerJQLike'});
      innerPromise.then(function (response) {
        resolve(response.data);
      }, function (reason) {
        reject(reason);
      });
    });
  }

  analytics.opportunity = function (params) {
    var requestParams = angular.extend({}, params);
    var url = '/api/opportunities/analytics';
    return request(url, requestParams);
  };

  analytics.message = function (params) {
    if (!params.id) {
      // return rejected promise
      return $q((r,j)=>{j()});
    }
    var url = '/api/messages/'+ params.id +'/analytics';
    return request(url, params.params);
  };

  return analytics;
};

$analyticsResource.$inject = ['$http', '$q'];

import "./planSelector.scss";

class _Controller {
  constructor(
    $opportunitiesAvailable,
    $message,
    $planTickboxes,
    $targetingOptions,
    UserService,
    $availablePlans
  ) {
    this.$opportunitiesAvailable = $opportunitiesAvailable;
    this.$message = $message;
    this.expanded = false;
    this.targetingOptions = $targetingOptions.get(
      this.$message.message.opportunity_type
    );
    this.$planTickboxes = $planTickboxes;
    this.user = UserService.user;
    this.$availablePlans = $availablePlans;
  }

  showMenu(e) {
    this.expanded = true;
  }

  hideMenu() {
    this.expanded = false;
  }

  onChangeTargetAll() {
    this.$message.setTargetingDirty();
    this.$planTickboxes.onChangeTargetAll();
    this.$message.message.settings.plans = this.$planTickboxes.getTargetedIds();
    this.onChange();
  }

  onTickPlan() {
    this.$message.setTargetingDirty();
    this.$planTickboxes.deselectTargetAll();
    this.$message.message.settings.plans = this.$planTickboxes.getTargetedIds();
    if (!this.$planTickboxes.getTargetedIds())
      this.$planTickboxes.targetAll = true;
    this.onChange();
  }

  refreshPlans() {
    this.$availablePlans.refresh();
  }
}

_Controller.$inject = [
  "$opportunitiesAvailable",
  "$message",
  "$planTickboxes",
  "$targetingOptions",
  "UserService",
  "$availablePlans",
];

export const planSelector = {
  controller: _Controller,
  template: require("./planSelector.html"),
  bindings: {
    onChange: "&",
  },
};

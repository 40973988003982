export const percentageCircle = (gradedColorClassFilter) => {
  return {
    restrict: 'E',
    require: ['^analytics'],
    scope: {},
    template: `
      <svg class="percentage-circle {{ctrl.typeA}}" width="170" height="170" viewPort="0 0 85 85" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <circle r="70" cx="85" cy="85" fill="transparent" stroke-dasharray="439.82297" stroke-dashoffset="0"></circle>
        <circle class="bar" r="70" cx="85" cy="85" fill="transparent" stroke-dasharray="439.82297" stroke-dashoffset="0"></circle>
      </svg>
    `,
    bindToController:{ typeA:'@', typeB: '@', label:'@', unit: '@', period: '=', target: '=' },
    controller: function () {

      this.valueA = null;
      this.valueB = null;
      this.value  = null;

      this.resolveValues = function (period) {
        this.service.resolve(period).then(function(response){
          this.valueA = response[this.typeA];
          this.valueB = response[this.typeB];
          this.value  = this.valueA / this.valueB;
        }.bind(this));
      };
    },
    controllerAs:'ctrl',

    link: function(scope, element, attrs, ctrls){
      var analytics = ctrls[0];
      scope.ctrl.service = analytics.service();
      scope.ctrl.resolveValues(scope.ctrl.period);
      scope.currency = analytics._currency;

      scope.$watch('ctrl.period', function (newV, oldV) {
        if (oldV != newV) scope.ctrl.resolveValues(scope.ctrl.period);
      }, true);

      var renderSVG = function() {
        var val = scope.ctrl.value * 100;
        var $circle = $(element).find(".bar");

        if (isNaN(val)) {
         val = 0;
        } else {

          var r = $circle.attr('r');
          var c = Math.PI*(r*2);
          if (val < 0) { val = 0;}
          if (val > 100) { val = 100;}
          var pct = ((100-val)/100)*c;
          $circle.css({ strokeDashoffset: pct});
        }

        var klass = gradedColorClassFilter(scope.ctrl.value, scope.ctrl.target);
        $(element).find('.percentage-circle.' + scope.ctrl.typeA).find('.bar').addClass(klass);

      };

      scope.$watch('ctrl.value', function (newV, oldV) {
        if (oldV != newV) renderSVG();
      }, true);

    }

  };
};

percentageCircle.$inject = ['gradedColorClassFilter'];
  
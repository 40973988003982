import "./advancedOptions.scss";

class _Controller {
  constructor($opportunitiesAvailable, $message, $advancedOptions) {
    this.$opportunitiesAvailable = $opportunitiesAvailable;
    this.$message = $message;
    this.expanded = false;
    this.$advancedOptions = $advancedOptions;
  }

  $onInit() {
    this.init()
  }

  $onChanges(changesObj) {
    this.init()
  }

  init() {
    if (this.$message.message.opportunity_type) {
      this.defaultAdvancedOptions = this.$advancedOptions.getTrigger(this.$message.message.opportunity_type);
    }

    if (this.defaultAdvancedOptions) {
      if (!this.$message.message.settings.advanced) {
        this.$message.message.settings.advanced = {};
      }
      _.each(this.defaultAdvancedOptions, (advancedObject, advancedId) => {
        if (!this.$message.message.settings.advanced.hasOwnProperty(advancedId)) {
          this.$message.message.settings.advanced[advancedId] = advancedObject.default;
        }
      });
    }

  }

  showMenu(e) {
    if (!this.$message.message.opportunity_type) return false;
    this.expanded = true;
  }

  hideMenu() {
    this.expanded = false;
  }

}

_Controller.$inject = ['$opportunitiesAvailable', '$message', '$advancedOptions'];

export const advancedOptions = {
  controller: _Controller,
  template: require("./advancedOptions.html"),
  bindings: {
    onChange: '&',
    opportunityType: '<'
  }
};
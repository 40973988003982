const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

export const $modal = function($timeout) {

  const modal = {
    name: null,
    targetElement: null,
    shown: false
  };

  modal.new = (targetElementSelector, onSuccess) => {
    modal.name = targetElementSelector.split(".")[1];
    modal.targetElement = document.querySelector(targetElementSelector);
    if (!modal.targetElement) {
      $timeout(() => modal.new(targetElementSelector), 150);
    } else {
      if (onSuccess) onSuccess();
    }
  }

  modal.show = () => {
    if (!modal.targetElement) return console.error("No target found. Initialize with `$modal.new('.css-selector')` first");
    disableBodyScroll(modal.targetElement);
    $timeout(() => { // Must be in timeout in case called from outside of angular
      modal.shown = true;
    })
  };

  modal.hide = event => {
    if (event) {
      let targetClass = event.target.classList.value;
      if (!["modal-holder-new", "dismiss-standard"].includes(targetClass)) return false;
    }
    enableBodyScroll(modal.targetElement);
    modal.shown = false;
  };

  return modal;

};

$modal.$inject = ['$timeout'];
function buildValues(number, type, immediately, now) {
  var values = _.times(number, function(i) {
    i++;
    var unit = i == 1 ? " day " : " days ";
    var result = { value: { value: i, type: type }, text: i + unit + type };
    if (now) result.value.now = now;
    return result;
  });
  if (immediately) {
    values.unshift({
      value: { value: 0, type: type },
      text: "immediately " + type
    });
  }
  return values;
}

export const $targetingOptions = () => {
  var options = {
    trial_start_after: {
      options: buildValues(30, "after", true),
      defaultIndex: 0,
      description: "a customer signs up for a trial",
      shortDescription: "a customer signs up for a trial"
    },
    trial_will_end_before: {
      options: buildValues(30, "before", false, "{{customer.trial_end}}"),
      defaultIndex: 3,
      description: "a customer's trial ends",
      shortDescription: "a customer's trial ends"
    },
    trial_expired_after: {
      options: buildValues(30, "after", true),
      defaultIndex: 0,
      description: "a customer's trial expires",
      shortDescription: "a customer's trial expires"
    },
    welcome_after: {
      options: buildValues(30, "after", true),
      defaultIndex: 0,
      description: "a customer purchases a paid subscription",
      shortDescription: "a customer purchases a subscription"
    },
    pre_dunning_before: {
      options: buildValues(
        30,
        "before",
        false,
        "{{customer.current_period_end}}"
      ),
      defaultIndex: 13,
      description: "a customer's next payment is due",
      shortDescription: "a customer's next payment is due"
    },
    dunning_after: {
      options: buildValues(30, "after", true),
      defaultIndex: 0,
      description: "a customer's payment fails",
      shortDescription: "a customer's payment fails"
    },
    invoice_receipt_after: {
      options: buildValues(30, "after", true),
      defaultIndex: 0,
      description: "a customer's invoice is paid",
      shortDescription: "a customer's invoice is paid"
    },
    charge_succeeded_after: {
      options: buildValues(30, "after", true),
      defaultIndex: 0,
      description: "a customer's charge is paid",
      shortDescription: "a customer's charge is paid"
    },
    renewed_after: {
      options: buildValues(30, "after", true),
      defaultIndex: 0,
      description: "a customer's subscription renews",
      shortDescription: "a customer's subscription renews"
    },
    renewed_before: {
      options: buildValues(
        30,
        "before",
        false,
        "{{customer.current_period_end}}"
      ),
      defaultIndex: 0,
      description: "a customer's subscription renews",
      shortDescription: "a customer's subscription renews"
    },
    upgrade_downgrade_after: {
      options: buildValues(30, "after", true),
      defaultIndex: 0,
      description: "a customer's plan changes",
      shortDescription: "a customer's plan changes"
    },
    card_updated_after: {
      options: buildValues(30, "after", true),
      defaultIndex: 0,
      description: "a customer's card is updated",
      shortDescription: "a customer's card is updated"
    },
    cancellation_recovery_after: {
      options: buildValues(30, "after", true),
      defaultIndex: 0,
      description: "a customer cancels their subscription",
      shortDescription: "a customer cancels their subscription"
    },
    subscription_will_end_before: {
      options: buildValues(
        30,
        "before",
        false,
        "{{customer.current_period_end}}"
      ),
      defaultIndex: 0,
      description: "a customer cancels their subscription",
      shortDescription: "a customer cancels their subscription"
    },
    checkout_completed_after: {
      options: buildValues(30, "after", true),
      defaultIndex: 0,
      description: "a customer's completes checkout",
      shortDescription: "a customer's completes checkout"
    }
  };

  return {
    options,
    get: function(key) {
      return this.options[key];
    },
    getDefault: function(opportunityType) {
      const _options = this.options[opportunityType];
      return _options.options[_options.defaultIndex];
    },
    getDescription: function(key) {
      return this.options[key].description;
    },
    getSelectedOption: function(opportunityType, value) {
      var fetchedOptions = this.get(opportunityType).options;
      var selectedOption;
      fetchedOptions.forEach(function(option) {
        if (selectedOption) return selectedOption;
        if (angular.equals(option.value, value)) selectedOption = option.value;
      });
      return (
        selectedOption ||
        fetchedOptions[this.options[opportunityType].defaultIndex].value
      );
    },
    getSelectedOptionText: function(opportunityType, value) {
      var fetchedOptions = this.get(opportunityType).options;
      var selectedOption;
      fetchedOptions.forEach(function(option) {
        if (selectedOption) return selectedOption;
        if (angular.equals(option.value, value)) selectedOption = option.text;
      });
      return (
        selectedOption ||
        fetchedOptions[this.options[opportunityType].defaultIndex].text
      );
    }
  };
};

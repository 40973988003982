class _Controller {
  constructor ($resourceManager, $notifier, userErrors) {
    this.$resourceManager = $resourceManager;
    this.$notifier = $notifier;
    this.user = {
      email: null
    };
    this.submitted = false;
    this.errors = userErrors({doValidate: ['email']});
  }

  onSuccess() {
    this.submitted = false;
    this.$notifier.green('Sent!');
  }
  
  submit() {
    
    this.submitted = true;
    this.errors.reset();

    if (!this.errors.any(this.user)) {
      this.submitted = false;
      return this.errors.notify();
    }

    this.$resourceManager.request('passwords', 'reset', {email: this.user.email})
      .then(this.onSuccess.bind(this))
      .catch(this.errors.notify)
      .finally(() => { this.submitted = false; });

  }

}

_Controller.$inject = ['$resourceManager', '$notifier', 'userErrors'];

export const passwordReset = {
  controller: _Controller,
  template: require('./passwordReset.html')
};
class _Controller {

  constructor($sce) {
    this.$sce = $sce;
  }

  $onInit() {
    this.salesTitle = this.trust(this.salesTitle);
    this.salesDesc = this.trust(this.salesDesc);
  }

  trust(html) {
    return this.$sce.trustAsHtml(html);
  }
}

_Controller.$inject = ['$sce'];

export const subscriptionSalesSplash = {
  controller: _Controller,
  template: require('./subscriptionSalesSplash.html'),
  bindings: {
    customer: '<',
    subscription: '<',
    newSubscription: '&',
    salesTitle: '@',
    salesDesc: '@',
    buttonText: '@'
  }
};
import "./bccSelector.scss";

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class _Controller {
  constructor($message) {
    this.$message = $message;
    this.expanded = false;
  }

  onChangeInternal() {
    this.errorEmail = false;
    this.onChange();
  }

  showMenu(e) {
    this.expanded = true;
  }

  hideMenu() {
    let bcc_email_address;
    if (this.$message.message.settings.advanced) {
      bcc_email_address = this.$message.message.settings.advanced.bcc_email_address;
    }
    if (bcc_email_address && !bcc_email_address.match(EMAIL_REGEX)) {
      this.errorEmail = "needs checking";
    } else {
      this.expanded = false;
    }
  }

}

_Controller.$inject = ['$message'];

export const bccSelector = {
  controller: _Controller,
  template: require("./bccSelector.html"),
  bindings: {
    onChange: '&',
    opportunityType: '<'
  }
};
export const perCent = function() {
  return function(text, skipSpan) {
    if (!_.isFinite(text) || _.isNaN(text) || _.isUndefined(text) || _.isNull(text)) return "&mdash;";
    var value = text * 100;
    var characteristic = value.toString().match(/([0-9]{1,})/)[0];

    var decimals = 3 - ((characteristic.length > 3) ? 3 : characteristic.length);

    var result = parseFloat(value).toFixed(decimals);

    if (skipSpan) {
      return result;
    } else {
      return result + '<span class=\"sup\">%</span>';
    }
  };
};

export const AnalyticQueue = ($analyticsResource) => {
  function _AnalyticQueue (callOptions, onComplete) {
    // all data items will make the same api call.
    // so we make the call once and give the response to each datum
    // --------------
    // this exists because the front end was making 3 calls per flow in flow index, usually all at the same time.
    // one will do k thankz bye

    var queue = {
      callOptions: callOptions,
      data: [],
      complete: onComplete
    };

    queue.onComplete = function (callback) {
      queue.complete = callback;
    };

    queue.add = function (item) {
      queue.data.push(item);
      // if the first item in data
      if (queue.data.length === 1) {
        queue.resolve();
      }
    };

    queue.resolve = function () {
      // make direct api call, then return response to all queue items
      var method = callOptions.method;
      var options = _.omit(callOptions, ['method']);

      var promise = $analyticsResource[method](options);

      // analyticsResource will also cache duplicate calls
      promise.then(function (response) {
        angular.forEach(queue.data, function (item) {
          item.defer.resolve(response);
        });
        if (queue.complete) queue.complete();
      }, function (reason) {
        angular.forEach(queue.data, function (item) {
          item.defer.reject(reason);
        });
        if (queue.complete) queue.complete();
      });
    };

    return queue;
  }

  return _AnalyticQueue;
};

AnalyticQueue.$inject = ['$analyticsResource'];
import "./messageNew.scss";
import promiseMinTimeout from "../../global/utils/promiseMinTimeout";

class _Controller {

  constructor ($resourceManager, $notifier, $location, $opportunitiesAvailable, $timeout, $message) {
    this.$resourceManager = $resourceManager;
    this.$notifier = $notifier;
    this.$location = $location;
    this.$timeout = $timeout;
    this.$opportunitiesAvailable = $opportunitiesAvailable;
    this.opportunities = $opportunitiesAvailable.list();
    this.loaded = false;
    this.$message = $message;
  }

  $onInit() {
    this.$message.new().then(() => {
      this.loaded = true;
    });
  }

  createFromTemplate() {
    this.$notifier.red('Soon...');
  }

  createFromScratch() {
    this.submittedScratch = true;
    const creationPromise = this.$message.saveNew();
    promiseMinTimeout(creationPromise, 500).then(response => {
      this.$timeout(() => {
        this.$location.path('/automations/' + response.message.id, false).search({});
      }, 300)
    });
  }

}

_Controller.$inject = ['$resourceManager', '$notifier', '$location', '$opportunitiesAvailable', '$timeout', '$message'];

export const messageNew = {
  controller: _Controller,
  template: require("./messageNew.html")
};
import "./app.scss";

class _Controller {
  constructor(
    UserService,
    Auth,
    $location,
    $meta,
    $notifier,
    $timeout,
    $availablePlans,
    $currentSender,
    $resourceManager,
    $availableProducts
  ) {
    this.Auth = Auth;
    this.$location = $location;
    this.$meta = $meta;
    this.$notifier = $notifier;
    this.$timeout = $timeout;
    this.$resourceManager = $resourceManager;

    $currentSender.initialize();
    $availablePlans.initialize();
    $availableProducts.initialize();

    this.UserService = UserService;
    this.user = UserService.user;
    this.subscribedState = null;
    this.subscription = {};
    this.customer = false;

    this.isDevelopment = __RAILS_ENV === "development";
    try {
      this.redirectURI = __STRIPE_REDIRECT_URI || "";
    } catch (e) {
      this.redirectURI = "";
    }

    this.banners = [];

    this.bannerTypes = [
      {
        id: "subscribed",
        type: "positive",
        text: "Success! Thank you for subscribing to Onboard."
      },
      {
        id: "subscribeFailed",
        type: "error",
        text:
          "Sorry, an error occurred. Please try again or contact <strong>support@onboardhq.com</strong>.</span>"
      },
      {
        id: "emailsWillPause",
        type: "warning",
        text:
          "Emails will pause on {{ $ctrl.subscription.current_period_end * 1000 | date: 'MMMM dd, yyyy'}}. <a href='/account/subscription' target='_self'>Re-subscribe to keep sending...</a>"
      },
      {
        id: "trialExpired",
        type: "warning",
        text:
          "We've paused emails because your trial has expired. <a href='/account/subscription' target='_self'>Add payment details to reactivate emails.</a>"
      },
      {
        id: "paymentFailed",
        type: "warning",
        text:
          "We've had to pause emails because your recent payment to us failed. <a href='/account/subscription' target='_self'>Update payment details to reactivate emails.</a>"
      },
      {
        id: "subscriptionExpired",
        type: "warning",
        text:
          "We've had to pause emails because your subscription expired. <a href='/account/subscription' target='_self'>Update payment details to reactivate emails.</a>"
      },
      {
        id: "alreadyConnected",
        type: "error",
        text:
          "Sorry, this Stripe account is already connected to another Onboard account. Please try again or contact <strong>support@onboardhq.com</strong>."
      },
      {
        id: "refusedConnection",
        type: "error",
        text:
          "You didn't give us access to your Stripe account. Please try again or contact <strong>support@onboardhq.com</strong> for more details on why we need it."
      }
    ];
  }

  $onInit() {
    let { subscribed, error } = this.$location.search();
    if (typeof subscribed !== "undefined") {
      if (subscribed == "true" || subscribed === true) {
        this.showBanner("subscribed");
      } else {
        this.showBanner("subscribeFailed");
      }
    }

    if (error === "provider_taken") this.showBanner("alreadyConnected");
    if (error === "access_denied") this.showBanner("refusedConnection");

    Promise.all([
      this.$resourceManager.request("users", "subscription", { id: "current" }),
      this.$resourceManager.request("users", "customer", { id: "current" })
    ]).then(([subscription, customer]) => {
      this.$timeout(() => {
        this.subscription = subscription.id ? subscription : false;
        this.customer = customer.id ? customer : false;
        this.daysUntilExpiry = this.calculateDaysUntilExpiry();

        if (
          ["active", "trialing"].includes(this.subscription.status) &&
          this.subscription.cancel_at_period_end
        )
          this.showBanner("emailsWillPause");

        if (
          this.subscription.status == "trialing" &&
          this.daysUntilExpiry < 5 &&
          !this.customer.invoice_settings.default_payment_method
        )
          this.showBanner("trialWillEnd");

        if (
          this.subscription.status == "past_due" &&
          this.subscription.trial_end == this.subscription.current_period_start
        )
          this.showBanner("trialExpired");

        if (
          this.subscription.status == "past_due" &&
          this.subscription.trial_end != this.subscription.current_period_start
        )
          this.showBanner("paymentFailed");

        if (this.subscription === false) this.showBanner("subscriptionExpired");
      });
    });

    this.$timeout(() => {
      var drift = drift || null;
      if (!!drift) {
        drift.identify("USER-" + this.user.id, {
          email: this.user.email
        });
      }
    }, 100);
  }

  showBanner(id) {
    const banner = _.findWhere(this.bannerTypes, { id });
    if (!!banner) {
      this.banners.unshift(banner);
    }
  }

  removeBanner(id) {
    let idx;
    for (let i = 0; i < this.banners.length; i++) {
      if (this.banners[i].id === id) idx = i;
    }
    this.banners.splice(idx, 1);
  }

  calculateDaysUntilExpiry() {
    var calculated = parseInt(
      (this.subscription.trial_end * 1000 - Date.now()) / (24 * 3600 * 1000)
    );
    return calculated < 0 ? -1 : calculated;
  }

  onError(error) {
    this.error = error;
    this.$notifier.red("Error.");
  }

  clearSubscribedState() {
    this.subscribedState = null;
  }

  clearErrorState() {
    this.error = null;
  }

  logout() {
    this.Auth.logout({ headers: { "X-HTTP-Method-Override": "DELETE" } }).then(
      () => {
        this.UserService.reset();
        // drift.reset();
        this.$timeout(() => {
          window.location = window.location.origin;
        }, 750);
      },
      error => {
        if (error.status == 306) {
          document.location.href = "/admin";
        }
      }
    );
  }
}

_Controller.$inject = [
  "UserService",
  "Auth",
  "$location",
  "$meta",
  "$notifier",
  "$timeout",
  "$availablePlans",
  "$currentSender",
  "$resourceManager",
  "$availableProducts"
];

export const app = {
  controller: _Controller,
  template: require("./app.html")
};

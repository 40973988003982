export const analyticsServiceController = function(opportunityAnalytics, messageAnalytics) {
  var services = {
    'opportunityAnalytics': opportunityAnalytics,
    'messageAnalytics': messageAnalytics
  };

  this.service = function () {
    return services[this._service](this._serviceItem);
  };
};

analyticsServiceController.$inject = ['opportunityAnalytics', 'messageAnalytics'];

const pretty = require("pretty");

const CSS = `body{height:100%!important;Margin:0 auto!important;padding:0!important;width:100%!important;min-width:100%!important;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;background-color:#fff}div[style*="margin: 16px 0"]{Margin:0 auto!important}table,td{border-collapse:collapse!important;mso-table-lspace:0;mso-table-rspace:0;border-spacing:0;font-family:sans-serif}td{padding:0}table img{outline:0;text-decoration:none;-ms-interpolation-mode:bicubic;width:100%;height:auto;border:0}p{Margin:0;font-size:18px;text-align:left;font-weight:400;line-height:150%;font-family:'Helvetica Neue',helvetica,arial,sans-serif;padding:0 0 21px 0}a{Margin:0;color:inherit;outline:0;text-decoration:underline;font-family:'Helvetica Neue',helvetica,arial,sans-serif}h1{Margin:0;font-size:36px;font-weight:400;line-height:130%;font-family:'Helvetica Neue',helvetica,arial,sans-serif;padding:0 0 36px 0}h2{Margin:0;font-size:23px;font-weight:400;line-height:130%;font-family:'Helvetica Neue',helvetica,arial,sans-serif;padding:10px 0 24px 0}em{font-style:italic}h3{font-weight:400}h4{font-weight:400}*{text-size-adjust:none;-webkit-text-size-adjust:none;-moz-text-size-adjust:none;-ms-text-size-adjust:none;-webkit-font-smoothing:antialiased}.ExternalClass,.ReadMsgBody{width:100%}.ExternalClass,.ExternalClass div,.ExternalClass font,.ExternalClass p,.ExternalClass span,.ExternalClass td{line-height:100%}.wrapper{width:100%;table-layout:fixed;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%}.webkit{max-width:600px}.outer{Margin:0 auto;width:100%;max-width:600px}.contents{width:100%;text-align:left}.button-container{padding:15px 0 36px 0}.main-content{padding:30px 30px 30px 30px;background-color:#fff}a{color:#3893FF}`;

const HEADER = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <!--[if !mso]><!-->
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <!--<![endif]-->
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>{{email.subject}}</title>
  <!--[if (gte mso 9)|(IE)]><style type="text/css">table {border-collapse: collapse;}</style><![endif]-->
  <style>
    /* Boilerplate CSS (All CSS is inlined during preflight) */
    [CSSHERE]
  </style>
  </head>

<body>
  <center class="wrapper">
    <div class="webkit">
    <!--[if (gte mso 9)|(IE)]>
      <table width="600" align="center" cellpadding="0" cellspacing="0" border="0">
      <tr>
      <td>
      <![endif]-->
      <table class="outer" align="center" role="presentation">
        <tr>
          <td class="inner">
            <table class="contents" role="presentation">
              <tr>
                <td class="main-content">`;

const FOOTER = `</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <!--[if (gte mso 9)|(IE)]>
      </td>
      </tr>
      </table>
      <![endif]-->
    </div>
  </center>
</body>
</html>
`;

export const $emailTemplates = () => {
  var templates = {
    trial_start_after: {
      subject: "Thanks for signing up for a trial!",
      body:
        "<p>Dear {{ customer.first_name | default: 'customer' }},</p>\n\n<p>Thanks for signing up for a trial!</p>\n\n<p>Your trial will end in {{subscription.days_until_trial_end}} days.</p>\n\n<p>Thanks,</p>\n\n<p>{{sender.name}}</p>"
    },
    trial_will_end_before: {
      subject: "Your trial subscription is due to end.",
      body:
        "<p>Dear {{ customer.first_name | default: 'customer' }},</p>\n\n<p>Your trial will end in {{subscription.days_until_trial_end}} days. If you like the product, why not sign up?</p>\n\n<p>Thanks,</p>\n\n<p>{{sender.name}}</p>"
    },
    trial_expired_after: {
      subject: "Your trial subscription has expired.",
      body:
        "<p>Dear {{ customer.first_name | default: 'customer' }},</p>\n\n<p>Thanks for checking out our product. Your trial has now ended.</p>\n\n<p>To sign up, visit the account area of our app.</p>\n\n<p>Thanks,</p>\n\n<p>{{sender.name}}</p>"
    },
    welcome_after: {
      subject: "Thanks for signing up!",
      body:
        "<p>Dear {{ customer.first_name | default: 'customer' }},</p>\n\n<p>Thanks for subscribing to a paid subscription. Your account has been updated with your new subscription details and is all ready to use.</p>\n\n<p>Just send us an email at {{ sender.email }} if you have any further questions.</p>\n\n<p>All the best,</p>\n\n<p>{{ sender.name }}</p>"
    },
    pre_dunning_before: {
      subject: "The card details we have on file are due to expire",
      body:
        "<p>Dear {{ customer.first_name | default: 'customer' }},</p>\n\n<p>Unfortunately the card we have on file ending in {{ customer.card.last4 }} will expire before your next payment is due.</p>\n\n<p>Please update your card details before {{ customer.active_subscription.current_period_end | date }} to avoid your subscription being cancelled. You can update your card details in the account area of our app.</p>\n\n<p>All the best,</p>\n\n<p>{{ sender.name }}</p>"
    },
    dunning_after: {
      subject: "Your payment has failed. Please update your payment details.",
      body: `<p>Dear {{ customer.first_name | default: 'customer' }},</p>\n\n<p>We tried to process your regular payment to us for {{ invoice.amount_due | money : invoice.currency }} from the card we have on file{% if customer.card.last4 %} ending in {{ customer.card.last4 }}{% endif %}, but the payment failed.</p>\n\n<p>Please update your card details to reactivate your subscription.</p>\n\n<p><a href='https://example.com/account' class='as-button'>Update payment details</a></p>\n\n<p>All the best,</p>\n\n<p>{{ sender.name }}</p>`
    },
    invoice_receipt_after: {
      subject: "Your payment was successful.",
      body:
        "<p>Dear {{ customer.first_name | default: 'customer' }},</p>\n\n<p>Thanks for subscribing.</p>\n\n<p>Your payment of {{invoice.total | money: invoice.currency }} to {{sender.name}} was successful.</p>\n\n<p>Thanks,</p>\n\n<p>{{sender.name}}</p>"
    },
    charge_succeeded_after: {
      subject: "Thank you for purchasing.",
      body:
        "<p>Dear {{ customer.first_name | default: 'customer' }},</p>\n\n<p>Your payment of {{invoice.total | money: invoice.currency }} to {{sender.name}} was successful.</p>\n\n<p>Thanks,</p>\n\n<p>{{sender.name}}</p>"
    },
    renewed_after: {
      subject: "Your subscription has renewed.",
      body:
        "<p>Dear {{ customer.first_name | default: 'customer' }},</p>\n\n<p>Thanks for renewing your subscription.</p>\n\n<p>Best regards,</p>\n\n<p>{{sender.name}}</p>"
    },
    renewed_before: {
      subject: "Your subscription will renew soon",
      body:
        "<p>Dear {{ customer.first_name | default: 'customer' }},</p>\n\n<p>Your subscription will renew in a few days' time.</p>\n\n<p>Best regards,</p>\n\n<p>{{sender.name}}</p>"
    },
    upgrade_downgrade_after: {
      subject: "Welcome to your new plan.",
      body:
        "<p>Dear {{ customer.first_name | default: 'customer' }},</p>\n\n<p>Your subscription has successfully been changed.</p>\n\n<p>Thanks,</p>\n\n<p>{{sender.name}}</p>"
    },
    card_updated_after: {
      subject: "Your card details have been updated",
      body:
        "<p>Dear {{ customer.first_name | default: 'customer' }},</p>\n\n<p>Just a quick email to let you know that the card details we have on file for you have been updated.</p>\n\n<p>We'll use these details when charging for your monthly subscription from now onwards.</p>\n\n<p>All the best,</p>\n\n<p>{{sender.name}}</p>"
    },
    cancellation_recovery_after: {
      subject: "We're sorry to see you go",
      body:
        "<p>Dear {{ customer.first_name | default: 'customer' }},</p>\n\n<p>You've recently cancelled your account with us. We're sorry to see you go.</p>\n\n<p>Is there anything we can do to convince you to stay? If so, simply reply to this email and let's talk. We may be able to sort something out.</p>\n\n<p>All the best,</p>\n\n<p>{{ sender.name }}</p>"
    },
    subscription_will_end_before: {
      subject: "Your subscription will end soon",
      body:
        "<p>Dear {{ customer.first_name | default: 'customer' }},</p>\n\n<p>Your subscription will end in a few days' time.</p>\n\n<p>Best regards,</p>\n\n<p>{{sender.name}}</p>"
    },
    checkout_completed_after: {
      subject: "Thank you for purchasing.",
      body:
        "<p>Dear {{ customer.first_name | default: 'customer' }},</p>\n\n<p>Your payment of {{event.amount_total | money: event.currency }} to {{sender.name}} was successful.</p>\n\n<p>Thanks,</p>\n\n<p>{{sender.name}}</p>"
    }
  };

  const wrapCodeEmail = body => {
    return pretty(`
      ${HEADER}
      ${body.replace(" class='as-button'", "")}
      ${FOOTER}
    `).replace("[CSSHERE]", CSS);
  };

  const wrap = (body, body_type) => {
    if (body_type === "code") return wrapCodeEmail(body);
    return body;
  };

  var methods = {
    getSubject: function(opportunityType) {
      return templates[opportunityType].subject;
    },
    getBody: function(opportunityType) {
      return templates[opportunityType].body;
    },
    populateSubject: function(email, opportunityType, force) {
      if (opportunityType && (email.subject === null || force)) {
        email.subject = templates[opportunityType].subject;
      }
      return email;
    },
    populateBody: function(email, opportunityType, body_type, force) {
      if (opportunityType && (email.body === null || force)) {
        email.body = wrap(templates[opportunityType].body, body_type);
      }
      return email;
    }
  };

  return methods;
};

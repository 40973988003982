const pollDelay = 4000;
const period = { start: moment().subtract(30, "days"), end: moment() };

const startJobs = (_this) => () => {
  _this.$activityStore.setupAndRequest(null, period);
};

class _Controller {
  constructor(
    $opportunitiesStatus,
    $opportunitiesAvailable,
    $timeout,
    $opportunitiesCount,
    $location,
    $cookies,
    $resourceManager,
    $activityStore,
    $cableConnection,
    UserService
  ) {
    this.$opportunitiesStatus = $opportunitiesStatus;
    this.$opportunitiesAvailable = $opportunitiesAvailable;
    this.$timeout = $timeout;
    this.$opportunitiesCount = $opportunitiesCount;
    this.$location = $location;
    this.$cookies = $cookies;
    this.$activityStore = $activityStore;
    this.opportunities = $opportunitiesAvailable.list();
    this.$resourceManager = $resourceManager;
    this.$cableConnection = $cableConnection;
    this.user = UserService.user;
    this.building = false;
    this.loaded = false;
    this.messageId = "global";

    this.isDevelopment = __RAILS_ENV === "development";
    try {
      this.redirectURI = __STRIPE_REDIRECT_URI || "";
    } catch (e) {
      this.redirectURI = "";
    }
  }

  $onInit() {
    this.$activityStore.reset("message-global", period);
    this.$cableConnection.onConnected(startJobs(this));

    this.loaded = true;
    this.$timeout(() => {
      Chart.defaults.LineWithLine = Chart.defaults.line;
      Chart.defaults.global.defaultFontFamily = "'Proxima Nova Regular";
      Chart.controllers.LineWithLine = Chart.controllers.line.extend({
        draw: function (ease) {
          Chart.controllers.line.prototype.draw.call(this, ease);

          if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
            var activePoint = this.chart.tooltip._active[0],
              ctx = this.chart.ctx,
              x = activePoint.tooltipPosition().x,
              topY = this.chart.scales["y-axis-0"].top,
              bottomY = this.chart.scales["y-axis-0"].bottom;

            // draw line
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 3;
            ctx.strokeStyle = "#485572";
            ctx.stroke();
            ctx.restore();
          }
        },
      });

      this.$resourceManager
        .request("analytics", "daily", { metric: "sent", live_mode: "true" })
        .then((res) => {
          this.buildSendsChart(res);
        });
    });
  }

  buildSendsChart(data) {
    const builtData = data.map((datum) => {
      return {
        x: new Date(datum.timestamp * 1000).setHours(0, 0, 0, 0),
        y: datum.sent,
      };
    });

    var ctx = document.getElementById("chart-sends-by-day");
    const myChart = new Chart(ctx, {
      type: "LineWithLine",

      data: {
        datasets: [
          {
            fill: "origin",
            backgroundColor: "rgba(96, 132, 226, 0.3)",
            borderWidth: 3,
            borderColor: "rgba(96, 132, 226, 1)",
            pointRadius: 2,
            pointBorderColor: "rgba(96, 132, 226, 1)",
            pointBackgroundColor: "rgba(96, 132, 226, 7)",
            lineTension: 0,
            borderJoinStyle: "round",
            label: false,
            data: builtData,
          },
        ],
      },
      options: {
        tooltips: {
          backgroundColor: "#ffffff",
          borderColor: "#ecedef",
          borderWidth: 2,
          bodyFontColor: "#7f93ab",
          titleFontColor: "#7f93ab",
          xPadding: 16,
          yPadding: 12,
          mode: "index",
          intersect: false,
          axis: "x",
          callbacks: {
            title: function (tooltipItem, data) {
              const dateTokens = tooltipItem[0].label.split(",");
              return `${dateTokens[0]}${dateTokens[1]}`;
            },
            label: function (tooltipItem, data) {
              const { yLabel } = tooltipItem;
              return `${yLabel} sends`;
            },
          },
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              label: false,
              gridLines: {
                drawTicks: false,
                zeroLineWidth: 2,
                borderDash: [1, 1],
              },
              type: "time",
              time: {
                unit: "day",
                stepSize: 3,
              },
              ticks: {
                padding: 12,
              },
              major: {
                enabled: true,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                drawTicks: false,
                zeroLineWidth: 2,
              },
              drawTicks: false,
              ticks: {
                padding: 6,
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }
}

_Controller.$inject = [
  "$opportunitiesStatus",
  "$opportunitiesAvailable",
  "$timeout",
  "$opportunitiesCount",
  "$location",
  "$cookies",
  "$resourceManager",
  "$activityStore",
  "$cableConnection",
  "UserService",
];

export const dashboard = {
  controller: _Controller,
  template: require("./dashboard.html"),
};

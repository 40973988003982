export const stripeForm = {
  bindings: {
    type: '@'
  },
  template: `
  <form stripe-card type='{{$ctrl.type}}' action="/ignore" method="post">
    <div class="card-element"></div>
    <div class="card-errors" role="alert"></div>
    <div class="form-row inline-block-children">
      {{ $ctrl.submitting }}
      <button type="submit" class='stripe-pay-standard' value="Purchase">Purchase</button>
    </div>
  </form>`
};
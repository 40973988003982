import "./refreshButton.scss";

class _Controller {
  refresh(e) {
    this.onClick(e);
  }
}

_Controller.$inject = [];

export const refreshButton = {
  controller: _Controller,
  template: `<div class="refresh-button" ng-class="{'refresh-button--ready': !$ctrl.loading, 'refresh-button--loading': $ctrl.loading}" ng-click="$ctrl.refresh()">
  </div>`,
  bindings: {
    onClick: "&",
    loading: "="
  }
};

import "./user/userBase.scss";

import { app } from "./app.component.js";
import { login } from "./user/login.component.js";
import { adminIndex } from "./user/admin/adminIndex.component.js";
import { signUp } from "./user/signUp.component.js";
import { passwordReset } from "./user/passwordReset.component.js";
import { passwordEdit } from "./user/passwordEdit.component.js";
import { userErrors } from "./user/userErrors.factory.js";

export const APP_MODULE = angular.module('app', []);

APP_MODULE.component('app', app);
APP_MODULE.component('signUp', signUp);
APP_MODULE.component('login', login);
APP_MODULE.component('adminIndex', adminIndex);
APP_MODULE.component('passwordReset', passwordReset);
APP_MODULE.component('passwordEdit', passwordEdit);
APP_MODULE.factory('userErrors', userErrors);

const states = [
  {
    name: 'app',
    redirectTo: 'dashboard',
    component: 'app',
    authenticate: true,
  },
  {
    name: 'login',
    url: '/login',
    component: 'login',
    title: 'Log in | Onboard'
  },
  {
    name: 'signUp',
    url: '/sign-up',
    component: 'signUp',
    title: 'Sign up | Onboard'
  },
  {
    name: 'adminIndex',
    url: '/admin',
    component: 'adminIndex',
    title: 'Admin | Onboard',
    authenticate: true,
    admin: true,
  },
  {
    name: 'passwordReset',
    url: '/reset-password',
    component: 'passwordReset',
    title: 'Reset your password | Onboard'
  },
  {
    name: 'passwordEdit',
    url: '/edit-password',
    component: 'passwordEdit',
    title: 'Enter a new password | Onboard'
  },
];


APP_MODULE.config(['$uiRouterProvider', 'AuthProvider', function($uiRouter, AuthProvider) {

  AuthProvider.loginPath('/users/login.json');
  AuthProvider.logoutPath('/users/logout.json');
  AuthProvider.resetPasswordMethod('PUT');
  AuthProvider.registerPath('/users.json');

  states.forEach(state => $uiRouter.stateRegistry.register(state));

}]).run(['ActionCableConfig', '$cableConnection', function (ActionCableConfig, $cableConnection) {
  ActionCableConfig.autoStart = false;
  $cableConnection.connect();
}]);

const promiseAllSettled = promises => {
  return Promise.all(
    promises.map((promise, i) =>
      promise
        .then(value => ({
          status: "fulfilled",
          value,
        }))
        .catch(reason => ({
          status: "rejected",
          reason,
        }))
    )
  );
}

export default function promiseMinTimeout(promise, ms) {
  // Create a promise that rejects in <ms> milliseconds
  const timeout = new Promise((resolve, reject) => {
    let id = setTimeout(() => {
      clearTimeout(id);
      resolve('Finished in ' + ms + 'ms.')
    }, ms)
  })

  return new Promise((resolve, reject) => {
    promiseAllSettled([
      promise,
      timeout
    ]).then(([statusPromise, statusTimeout]) => {
      if (statusPromise.status === "fulfilled") promise.then(resolve);
      else promise.catch(reject);
    }).catch(reject);
  })
}
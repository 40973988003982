export const $emailDesign = (UserService, $modal) => {

  window.editorStore = window.editorStore || {};

  const defaults = {
    text:   UserService.user.settings.branding.textColor   || '#222222',
    link:   UserService.user.settings.branding.linkColor   || '#3893ff',
    button: UserService.user.settings.branding.buttonColor || '#3893ff',
    h1: UserService.user.settings.branding.h1Color || '#3893ff',
    h2: UserService.user.settings.branding.h2Color || '#222222'
  };

  let api = {};

  api.set = colors => {
    _.keys(colors).forEach(key => {
      const _color = colors[key] || defaults[key];
      api[key + 'Color'] = _color;
      window.editorStore[key + 'Color'] = _color;
    });
  };

  window.editorStore.open = () => {
    console.log('do it');
    $modal.new('.design-modal-show', $modal.show);
  }

  api.set(defaults);

  return api;

};

$emailDesign.$inject = ['UserService', '$modal'];
class _Controller {

  constructor($timeout) {
    this.$timeout = $timeout;
  }

  $onInit() {
    this.$timeout(() => {
      this.loaded = true;
    }, 100);
  }

  
}

_Controller.$inject = ['$timeout'];

export const accountDeliverability = {
  controller: _Controller,
  template: require('./accountDeliverability.html')
};

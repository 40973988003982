class _Controller {

  constructor($resourceManager, Auth, $notifier, UserService) {
    this.$resourceManager = $resourceManager;
    this.Auth = Auth;
    this.user = UserService.user;
    this.stripeAccount = null;
    this.$notifier = $notifier;
    this.loaded = false;
    this.newPassword = {
      password: null,
      password_confirmation: null
    };
    this.isDevelopment = (__RAILS_ENV === "development");
    try {
      this.redirectURI = __STRIPE_REDIRECT_URI || "";
    }
    catch (e) {
      this.redirectURI = "";
    }
  }

  $onInit() {
    this.$resourceManager.request('users', 'stripe_account', {id: 'current'}).then(response => {
      this.stripeAccount = (response.id) ? response : false;
      this.loaded = true;
    });
  }

  reset() {
    this.Auth.currentUser().then(response => {
      var userId = response.user.id;
      this.$resourceManager.request('users', 'update_password', {id: userId}, this.newPassword).then(() => {
        this.newPassword = {
          password: null,
          password_confirmation: null
        };
        this.$notifier.green('Changed.');
      });
    });
  }
}

_Controller.$inject = ['$resourceManager', 'Auth', '$notifier', 'UserService'];

export const accountDetails = {
  controller: _Controller,
  template: require('./accountDetails.html')
};

export function pageChangeAppHook($transitions, $meta, UserService, $state, Auth, $message, $redirects, transitionChecker, $anchorScroll, $location, $timeout, $activityStore) {

  $transitions.onBefore({}, transition => {

    // Clear the current message and activity store. Perhaps this should be in onStart rather than onBefore?
    $message.reset();
    $activityStore.reset();

    const toState = transition.to();
    const parent = (toState.parent) ? $state.get(toState.parent) : false;
    const { stateService } = transition.router;

    const needsAdmin = () => ((toState.admin || parent.admin));
    const needsAuth  = () => ((toState.authenticate || parent.authenticate));
    const userFunctionPages  = () => (['login', 'signUp', 'passwordReset', 'passwordEdit'].includes(toState.name));

    if (needsAuth() || needsAdmin() || userFunctionPages()) {
      return Auth.currentUser().then(response => {
        const { user } = response;
        UserService.initialize(user);

        if (needsAdmin() && !user.admin) {
          return stateService.target('dashboard');
        }

        const redirect = $redirects.check(user, toState);
        if (redirect) return stateService.target(redirect);

        return true;

      }).catch(error => {
        console.error(error);
        if (error.status == 401) {
          if (userFunctionPages()) return true;
          return stateService.target('login');
        } else {
          return stateService.target('home');
        }
      });
    } else {
      return true;
    }

  });

  // Set meta (title/section details) if page change is successful
  $transitions.onSuccess({}, transition => {

    $meta.setFromState(transition.to())
    // if ($location.hash()) {
      // $timeout(() => $anchorScroll(), 150);
    // }

  })

}

pageChangeAppHook.$inject = ['$transitions', '$meta', 'UserService', '$state', 'Auth', '$message', '$redirects', 'transitionChecker', '$anchorScroll', '$location', '$timeout', '$activityStore'];

import "./account.scss";
import "./accountDocuments.scss";
import "./jquery.minicolors.scss";

import { account } from "./account.component.js";
import { accountDetails } from "./accountDetails.component.js";
import { accountSubscription } from "./accountSubscription.component.js";
import { accountSettings } from "./accountSettings.component.js";
import { accountDeliverability } from "./accountDeliverability.component.js";
import { accountDocuments } from "./accountDocuments.component.js";
import { cardOverview } from "./subscription_components/cardOverview.component.js";
import { updateCard } from "./subscription_components/updateCard.component.js";
import { cancelSubscription } from "./subscription_components/cancelSubscription.component.js";
import { contactSupport } from "./subscription_components/contactSupport.component.js";
import { subscriptionSalesSplash } from "./subscription_components/subscriptionSalesSplash.component.js";
import { $emailDesign } from "./emailDesign.service.js";

export const ACCOUNT_MODULE = angular.module("account", []);

ACCOUNT_MODULE.component("account", account);
ACCOUNT_MODULE.component("accountDetails", accountDetails);
ACCOUNT_MODULE.component("accountSubscription", accountSubscription);
ACCOUNT_MODULE.component("accountSettings", accountSettings);
ACCOUNT_MODULE.component("accountDeliverability", accountDeliverability);
ACCOUNT_MODULE.component("accountDocuments", accountDocuments);
ACCOUNT_MODULE.component("cardOverview", cardOverview);
ACCOUNT_MODULE.component("updateCard", updateCard);
ACCOUNT_MODULE.component("cancelSubscription", cancelSubscription);
ACCOUNT_MODULE.component("contactSupport", contactSupport);
ACCOUNT_MODULE.component("subscriptionSalesSplash", subscriptionSalesSplash);
ACCOUNT_MODULE.service("$emailDesign", $emailDesign);

const states = [
  {
    name: "account",
    url: "/account",
    component: "account",
    parent: "app",
    section: "account",
    title: "Account | Onboard",
    authenticate: true
  },
  {
    name: "accountDetails",
    url: "/account/details",
    component: "accountDetails",
    parent: "app",
    section: "account",
    title: "Account details | Onboard",
    page: "details"
  },
  {
    name: "accountSubscription",
    url: "/account/subscription",
    component: "accountSubscription",
    title: "Subscription details | Onboard",
    parent: "app",
    section: "account",
    page: "subscription"
  },
  {
    name: "accountSettings",
    url: "/account/settings",
    component: "accountSettings",
    title: "Settings | Onboard",
    parent: "app",
    section: "account",
    page: "settings"
  },
  {
    name: "accountDeliverability",
    url: "/account/deliverability",
    component: "accountDeliverability",
    title: "Deliverability | Onboard",
    parent: "app",
    section: "account",
    page: "deliverability"
  },
  {
    name: "accountDocuments",
    url: "/account/documents",
    component: "accountDocuments",
    title: "Documents | Onboard",
    parent: "app",
    section: "account",
    page: "documents"
  }
];

ACCOUNT_MODULE.config([
  "$uiRouterProvider",
  function($uiRouter) {
    states.forEach(state => $uiRouter.stateRegistry.register(state));
  }
]);

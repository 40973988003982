class _Controller {

  constructor() {}
}

_Controller.$inject = [];

export const contactSupport = {
  controller: _Controller,
  template: `
    <div class='sub-section'>
      <label class='label sub-section-header'>Contact support</label>
      <p>Contact <a href='mailto://support@onboardhq.com'>support@onboardhq.com</a> if you have any questions about your subscription.</p>
    </div>
  `,
};


export const gradedColorClass = function() {
  return function(value, target) {
    if (!value) return 'graded-null';
    var classes = ['graded-1', 'graded-2', 'graded-3', 'graded-4', 'graded-5', 'graded-6', 'graded-7', 'graded-8', 'graded-9'];
    var bracket = 0.4;
    var quarterBracket = bracket / 4;
    var threeQuarterBracket = bracket / 4 * 3;
    var tickSize = bracket / classes.length;
    var diff, index;

    diff = (value - target); // Get the difference
    diff = Math.min(Math.max(diff, -threeQuarterBracket), quarterBracket); // Clamp diff by bracket
    diff = diff + threeQuarterBracket; // Offset clampdiff so the min is zero
    index = Math.round(diff / tickSize); // Get index of diff along a graded scale from min to max bracket of bracket. 

    if (!index) index = 1;
    return classes[index - 1];
  };
};

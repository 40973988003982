import "./bodyTypePrompt.scss";

class _Controller {
  constructor(
    $location,
    $notifier,
    $targetingOptions,
    $opportunitiesAvailable,
    $timeout,
    $q,
    $message,
    $connectModal,
    UserService,
    $modal,
    ScrollLock,
    $emailTemplates,
    $planTickboxes
  ) {
    this.$location = $location;
    this.$notifier = $notifier;
    this.$connectModal = $connectModal;
    this.$opportunitiesAvailable = $opportunitiesAvailable;
    this.$timeout = $timeout;
    this.$q = $q;
    this.$planTickboxes = $planTickboxes;
    $modal.new(".body-type-modal");
    this.$modal = $modal;
    this.$emailTemplates = $emailTemplates;
    this.$targetingOptions = $targetingOptions;
    this.sendTimeDescription = null;
    this.user = UserService.user;

    this.$message = $message;
    this.message = $message.message;

    this.email = $message.email;
    this.opportunity = $message.opportunity;

    this.previewHTML = null;
    this.processing = false;
    this.senderContinueEnabled = true;
    this.errors = {};

    this.isDirty = false;

    this.emailEditor = false;
    this.emailEditorLock = new ScrollLock("email-editor");

    this.triggers = [
      {
        value: "welcome",
        title: "Subscription purchased",
        group: "Onboarding",
      },
      { value: "trial_start", title: "Started trial", group: "Onboarding" },
      {
        value: "trial_will_end",
        title: "Trial ends soon",
        group: "Onboarding",
      },
      { value: "trial_expired", title: "Trial expired", group: "Onboarding" },
      {
        value: "dunning",
        title: "Payment failed (aka dunning)",
        group: "Payment emails",
      },
      {
        value: "pre_dunning",
        title: "Payment will fail (aka pre-dunning)",
        group: "Payment emails",
      },
      {
        value: "charge_succeeded",
        title: "Charge succeeded",
        group: "Payment emails",
      },
      {
        value: "invoice_receipt",
        title: "Invoice paid",
        group: "Payment emails",
      },
      {
        value: "cancellation_recovery",
        title: "Subscription cancelled",
        group: "Lifecycle emails",
      },
      {
        value: "renewed",
        title: "Subscription renewed",
        group: "Lifecycle emails",
      },
      {
        value: "renewed",
        title: "Subscription will renew",
        group: "Lifecycle emails",
      },
      {
        value: "upgrade_downgrade",
        title: "Plan changed",
        group: "Lifecycle emails",
      },
      { value: "card_updated", title: "Card updated", group: "Misc" },
    ];
  }

  $onInit() {
    this.hideModal();
    const id = this.$location.path().match(/\/automations\/([0-9]+)/)[1];
    this.$message.get(id).then(() => {
      this.body = this.$message.email.body;
      this.targeting = this.$message.message.settings.targeting;
      if (this.$message.message.settings.targeting) {
        this.populateSendTimeOptions();
      }
      if (this.$message.message.state === "started") {
        this.isActivated = true;
      }
    });
  }

  onChangeTrigger() {
    this.isDirty = true;
    this.updateSendTimeOptions();
    this.$message.updateOpportunity();
  }

  onChangeAdvanced() {
    this.isDirty = true;
  }

  onChangeTiming() {
    if (this.$message.message.state === "started") {
      alert("Pause this automation if you'd like to edit the time targeting.");
      this.targeting = this.$message.message.settings.targeting;
      return false;
    }
    this.$message.message.settings.targeting = this.targeting;
    this.isDirty = true;
  }

  onChangeSubject() {
    this.isDirty = true;
    this.errors.subject = null;
  }

  onChangeContent() {
    this.isDirty = true;
  }

  onChangeBcc() {
    this.isDirty = true;
  }

  updateSendTimeOptions() {
    if (!this.$message.message.settings.targeting) {
      this.populateSendTimeOptions();
      this.setDefaultSendingTime();
      this.targeting = this.$message.message.settings.targeting;
      return;
    }

    this.sendTimeDescription = null;

    const opportunityType = this.$message.message.opportunity_type;
    const targetingOptions = this.$targetingOptions.get(opportunityType);
    const { value, type } = this.$message.message.settings.targeting;
    const { options } = targetingOptions;
    const matchingOptions = _.filter(options, (item) => {
      return item.value.value == value && item.value.type == type;
    });

    this.$timeout(() => {
      this.populateSendTimeOptions();
      if (matchingOptions.length > 0) {
        this.$message.message.settings.targeting = matchingOptions[0].value;
      } else {
        this.setDefaultSendingTime();
      }
      this.targeting = this.$message.message.settings.targeting;
    }, 250);
  }

  populateSendTimeOptions() {
    const targetingOptions = this.$targetingOptions.get(
      this.$message.message.opportunity_type
    );
    this.sendTimeOptions = targetingOptions.options;
    this.sendTimeDescription = targetingOptions.description;
  }

  setDefaultSendingTime() {
    const defaultValue = this.$targetingOptions.getDefault(
      this.$message.message.opportunity_type
    );
    this.$message.message.settings.targeting = defaultValue.value;
  }

  hideModal() {
    this.newConnectModal = false;
  }

  goToSection(requested) {
    this.$message.goToSection(requested);
  }

  validatePlanMode() {
    const { live_mode } = this.$message.message;
    const plans = this.$planTickboxes.plans;
    const targeted = _.filter(plans, (_plan) => {
      return _.contains(this.$message.message.settings.plans, _plan.plan_id);
    });
    return !_.findWhere(targeted, { live_mode });
  }

  validateForActivated() {
    if (!this.$message.email.subject) {
      alert("Activated automations require a subject line");
      this.errors.subject = "Enter a subject line";
      return false;
    } else if (!this.$message.message.opportunity_type) {
      alert("Pick a trigger first!");
    } else if (!this.validatePlanMode()) {
      const confirmMessage = this.$message.message.live_mode
        ? "This is a live mode automation, but you're targeting some test mode plans. These will be ignored. Do you want to continue?"
        : "This is a test mode automation, but you're targeting some live mode plans. These will be ignored. Do you want to continue?";
      return confirm(confirmMessage);
    } else {
      return true;
    }
  }

  requestSave() {
    if (this.isActivated) {
      return this.validateForActivated() ? this.save() : false;
    } else {
      this.save();
    }
  }

  save() {
    this.$message.save(() => {
      this.isDirty = false;
    }, true);
  }

  onChangeActivate() {
    if (this.isActivated) {
      this.isActivated = false; // Deny the user having activated. If they pass validation, we'll activate for them.
      this.activate();
    } else {
      this.deactivate();
    }
  }

  onChangePlan() {
    this.isDirty = true;
  }

  onChangeProduct() {
    this.isDirty = true;
  }

  onChangeLiveMode() {
    this.isDirty = true;
  }

  activate() {
    if (this.isDirty) return alert("Save changes before activating");
    if (this.validateForActivated()) {
      if (!this.user.provider_uid) {
        this.$connectModal.new();
      } else if (
        confirm("Are you sure you want to activate this automatic email?")
      ) {
        this.$message.activate();
        this.isActivated = true;
      }
    }
  }

  deactivate() {
    if (
      confirm(
        "Are you sure you want to stop this automatic email from sending?"
      )
    ) {
      this.$message.deactivate();
    } else {
      this.isActivated = true;
    }
  }

  cancelNewEmailContent(event) {
    if (event) event.preventDefault();
    this.$modal.hide();
  }

  newEmailContent() {
    this.$modal.show();
  }

  createEmail(type) {
    this.$modal.hide();
    this.setBodyType(type);
    this.$message.email.save().then(() => {
      this.showEmailEditor();
    });
  }

  showEmailEditor() {
    this.emailEditorLock.on();
    this.emailEditor = true;
  }

  hideEmailEditor() {
    this.emailEditorLock.off();
    this.emailEditor = false;
    this.body = this.$message.email.body;
    this.$modal.new(".body-type-modal");
  }

  deleteEmailContent() {
    if (
      confirm(
        "Are you sure you want to delete this email content? This cannot be undone."
      )
    ) {
      this.$message.email.body_type = null;
      this.$message.email.body = null;
      this.$message.email.save().then(() => {
        this.body = " "; // Space, so emailThumbnail will be updated.
      });
    }
  }

  setBodyType(bodyType) {
    this.$message.email.body_type = bodyType;
    let opportunityType = this.$message.message.opportunity_type;
    this.$emailTemplates.populateBody(
      this.$message.email,
      opportunityType,
      bodyType,
      true
    );
  }
}

_Controller.$inject = [
  "$location",
  "$notifier",
  "$targetingOptions",
  "$opportunitiesAvailable",
  "$timeout",
  "$q",
  "$message",
  "$connectModal",
  "UserService",
  "$modal",
  "ScrollLock",
  "$emailTemplates",
  "$planTickboxes",
];

export const messageShow = {
  controller: _Controller,
  template: require("./messageShow.html"),
};

class _Controller {
  constructor () {
  }
}

_Controller.$inject = [];

export const messages = {
  controller: _Controller,
  // template: require('./messages.html')
};

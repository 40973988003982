class _Controller {
  constructor(UserService, $resourceManager) {
    this.user = UserService.user;
    this.$resourceManager = $resourceManager;
    this.page = 1;
    this.loading = false;
    this.isProduction = __IS_PRODUCTION;
    this.emailSearch = null;
  }

  $onInit() {
    this.getUsers(1);
  }

  loginUser(user) {
    window.location =
      window.location.origin + "/api/admin/users/" + user.id + "/login";
  }

  lockUser(user) {
    if (confirm("Are you sure you want to lock this user?")) {
      this.loading = true;
      this.$resourceManager
        .request("admin_users", "lock", { id: user.id })
        .then(res => {
          this.loading = false;
          user.locked = true;
        });
    }
  }

  deleteUser(user, users) {
    if (
      confirm(
        "Are you sure you want to delete this user and their Stripe customer?"
      )
    ) {
      this.loading = true;
      this.$resourceManager
        .request("admin_users", "delete", { id: user.id })
        .then(res => {
          const index = this.users.indexOf(user);
          if (index > -1) {
            this.users.splice(index, 1);
          }
        });
    }
  }

  unlockUser(user) {
    this.loading = true;
    this.$resourceManager
      .request("admin_users", "unlock", { id: user.id })
      .then(res => {
        this.loading = false;
        user.locked = false;
      });
  }

  getUsers(page, email) {
    this.loading = true;
    this.$resourceManager
      .request("admin_users", "query", { page, email: email })
      .then(res => {
        this.loading = false;
        if (res.users.length > 0 || !!this.emailSearch) {
          this.page = page;
          this.users = res.users;
        }
      });
  }

  next() {
    if (this.loading) return false;
    this.getUsers(this.page + 1);
  }

  prev() {
    if (this.loading) return false;
    if (this.page === 1) return false;
    this.getUsers(this.page - 1);
  }
}

_Controller.$inject = ["UserService", "$resourceManager"];

export const adminIndex = {
  controller: _Controller,
  template: require("./adminIndex.html")
};

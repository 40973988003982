import "./emailThumbnail.scss"

class _Controller {

  constructor ($resourceManager, $timeout) {
    this.$resourceManager = $resourceManager;
    this.$timeout = $timeout;
  }
  
  $onInit() {
    if (this.body_type) this.loadPreview();
  }

  $onChanges() {
    this.loadPreview();
  }

  fixZoomLevel(html) {
    const [ before, after ] = html.split("<style>");
    return before + "<style>body { transform: scale(0.405); transform-origin: 50% 5%; -moz-transform: scale(0.405); -moz-transform-origin: 50% 5%; -webkit-transform: scale(0.405); -webkit-transform-origin: 50% 5%; -o-transform: scale(0.405); -o-transform-origin: 50% 5%; }" + after;
  }

  loadPreview() {
    const body_type = this.body_type || "code"; 
    if (this.body && body_type) {
      if (this.clientId === "") this.clientId = null;
      let payload = {subject: this.subject, body: this.body, body_type: body_type};
      this.$resourceManager.request('emails', 'preview', null, payload).then(response => {
        this.updateIframe('desktop-preview-thumbnail', this.fixZoomLevel(response.html));
      });
    }
  }

  updateIframe(iframeId, html) {
    var iframe = document.getElementById(iframeId);
    var doc = iframe.contentWindow.document;
    doc.documentElement.innerHTML = html;
  }
}

_Controller.$inject = ['$resourceManager', '$timeout'];

export const emailThumbnail = {
  controller: _Controller,
  template: require('./emailThumbnail.html'),
  bindings: {
    body: '<',
    body_type: '<bodyType'
  },
};
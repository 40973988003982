export const $scheduledStore = ($resourceManager, $cableConnection) => {
  let store = {
    loading: true,
    messages: {},
    requestIds: {},
    failed: false,
  };

  store.add = (message) => {
    const { request_id, data } = message;
    const message_id = store.requestIds[request_id];

    if (message_id) {
      store.loading = false;
      if (!data.no_scheduled_jobs) {
        store.messages[message_id].push(data);
      }
    }
  };

  store.request = (message_id) => {
    if (!store.messages[message_id]) {
      const request_id = `scheduled-${new Date().getTime()}`;

      store.requestIds[request_id] = message_id;
      store.messages[message_id] = [];

      $resourceManager
        .request("opportunties", "scheduled", {
          request_id,
          message_id,
        })
        .then((response) => {
          store.loading = true;
        })
        .catch(() => {
          store.loading = false;
          store.failed = true;
        });
    }
  };

  $cableConnection.registerFunction((message) => {
    if (message.data) {
      store.add(message);
    }
  });

  return store;
};

$scheduledStore.$inject = ["$resourceManager", "$cableConnection"];

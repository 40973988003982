export const dotItem = () => {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      callback: '&',
      label: '@',
      extraClass: '@',
      disabled: '='
    },
    template: "<li><button class='standard {{extraClass}}' ng-click='callback()' tabindex='1' ng-disabled='disabled'>{{::label}}</button></li>"
  };
};
export const callout = ($timeout, $compile) => {

  return {
    restrict: 'A',
    scope: {
      callout: '@',
      calloutDirection: '@',
      calloutBehaviour: '@',
      calloutEnabled: '=',
    },
    link: function (scope, element) {

      scope.calloutDirection = scope.calloutDirection || 'bottom';
      scope.show = false;
      scope.isDisabled = null;
      var template = "<div class='callout {{calloutDirection}} fade-in' ng-show='show' ng-bind-html='callout'></div>";

      var input = element.find('> [ng-disabled]');

      $timeout(function() {
        var calloutElement = $compile(template)(scope);
        element.append(calloutElement);
      });

      scope.$watch(function() {
        return input.attr('disabled');
      }, function(disabled){
        scope.isDisabled = !!disabled;
      });

      const setEnabled = (newVal) => {
        if (!scope.calloutEnabled && typeof scope.calloutEnabled != "boolean") {
          scope.enabled = true;
        } else {
          scope.enabled = scope.calloutEnabled;
        }
      }
      scope.$watch('calloutEnabled', setEnabled);

      setEnabled();

      if (scope.calloutBehaviour === 'disabled hover') {
        $(element).hover(function(){
          $timeout(function() { scope.show = scope.isDisabled; });
        }, function(){
          $timeout(function() { scope.show = false; });
        });
      }

      if (scope.calloutBehaviour === 'hover') {
        $(element).hover(function(){
          $timeout(function() { scope.show = scope.enabled; });
        }, function(){
          $timeout(function() { scope.show = false; });
        });
      }

      if (scope.calloutBehaviour === 'disabled click') {
        var timer;
        $(element).click(function(){
          $timeout.cancel(timer);
          $timeout(function() {
            scope.show = scope.isDisabled;
            timer = $timeout(function() { scope.show = false; }, 1500);
          });
        });
      }
      
      if (scope.calloutBehaviour === 'click') {
        var timer;
        $(element).click(function(){
          $timeout.cancel(timer);
          $timeout(function() {
            scope.show = scope.enabled;
            timer = $timeout(function() { scope.show = false; }, 1500);
          });
        });
      }

    }
  };
};

callout.$inject = ['$timeout', '$compile'];
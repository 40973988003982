class _Controller {
  constructor($connectModal, $message) {
    this.$message = $message;
    this.$connectModal = $connectModal;
    this.isDevelopment = (__RAILS_ENV === "development")
  }
}

_Controller.$inject = ['$connectModal', '$message'];

export const connectModal = {
  controller: _Controller,
  template: require('./connectModal.html')
};
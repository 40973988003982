export const userErrors = () => {
  const _userErrors = options => {
    
    const { doValidate } = options;

    const validators = {
      email: user => {
        if (!user.email || !user.email.match(/.+@.+/)) {
          return "requires checking";
        }
      },
      password: user => {
        if (!user.password || (user.password && user.password.length < 6)) {
          return "requires 6 characters or more";
        }
      },
      password_confirmation: user => {
        if (!user.password_confirmation || (user.password != user.password_confirmation)) {
          return "doesn't match password";
        }
      }
    };

    var errors = {
      doValidate,
      validators
    };

    errors.notify = response => {
      if (response) {
        if (response.data.error) {
          errors.generic = response.data.error;
        } else if (response.status == 404) {
          errors.email = "account not found";
        } else {
          angular.forEach(response.data.errors, (val, key) => {
            errors[key] = val.join(",");
          });
        }
      }
    };

    errors.any = user => {
      var valid = true;
      errors.doValidate.forEach(field => {
        let validationError = errors.validators[field](user);
        if (validationError) {
          valid = false;
          errors[field] = validationError;
        }
      });
      return valid;
    };

    errors.reset = () => {
      errors.doValidate.forEach(key => delete errors[key]);
      delete errors.generic;
    };

    return errors;

  };

  return _userErrors;

};
function analyticsTotalController () {
  this.reset = function () {
    this.value = null;
    this.loading = false;
  };

  this.reset();

  this.resolveValues = function (period) {
    this.loading = true;
    this.service.resolve(period).then(function (response) {
      this.loading = false;
      this.value = this.type ? response[this.type] : response;
    }.bind(this), function (reason) {
      this.reset();
      this.error = true;
    }.bind(this));
  };
}

export const analyticsTotal = () => {
  return {
    restrict: 'E',
    require: ['^analytics'],
    scope: {},
    template: require('./total.html'),
    bindToController: { type: '@', label: '@', description: '@', unit: '@', period: '=' },
    controller: analyticsTotalController,
    controllerAs: 'ctrl',
    link: function (scope, element, attrs, ctrls) {
      var analytics = ctrls[0];
      scope.ctrl.service = analytics.service();
      scope.ctrl.resolveValues(scope.ctrl.period);
      scope.ctrl.currency = analytics._currency;

      scope.$watch('ctrl.period', function (newV, oldV) {
        if (oldV !== newV) scope.ctrl.resolveValues(scope.ctrl.period);
      }, true);
    }
  };
};

import promiseMinTimeout from "../../global/utils/promiseMinTimeout";

class _Controller {

  constructor ($resourceManager, $notifier, $location, $opportunitiesAvailable, $message, $timeout) {
    this.$resourceManager = $resourceManager;
    this.$message = $message;
    this.$notifier = $notifier;
    this.$location = $location;
    this.$timeout = $timeout;
    this.$opportunitiesAvailable = $opportunitiesAvailable;
    this.opportunities = $opportunitiesAvailable.list();
    this.messages = [];
    this.messageType = $location.search().opportunity_type || 'welcome';
    this.loaded = false;
    this.submittedCreate = false;
    this.completeCreate = false;
    this.triggerFilter = null;
  }

  buildMessageCounts () {
    return _.reduce(this.opportunities, (object, opportunity) => {
      object[opportunity.id] = _.where(this.messages, {opportunity_type: opportunity.id}).length;
      return object;
    }, {});
  }

  $onInit() {
    this.getMessages({}); 
  }

  getMessages(params) {
    const promise = this.$resourceManager.request('messages', 'query', params);
    promiseMinTimeout(promise, 500).then(response => {
      this.$timeout(() => {
        angular.copy(response.messages, this.messages);
        this.messageCounts = this.buildMessageCounts();
        this.loaded = true;
        this.processing = false;
      });
    });
  }

  setMessageType(opportunityType) {
    this.$location.search('opportunity_type', opportunityType);
    this.messageType = opportunityType;
  }

  setFilter(value) {
    if (value) {
      this.triggerFilter = value;
      this.processing = true;
      this.getMessages({opportunity_type: value});
    }
  }
  
  clearFilter() {
    this.processing = true;
    this.triggerFilter = null;
    this.getMessages({});
  }

  // createMessage(opportunityType) {
  //   this.$location.path('/automations/new').search({type: opportunityType});
  // }

  createAutomation() {
    this.$message.new().then(() => {
      this.submittedCreate = true;
      const creationPromise = this.$message.saveNew();
      promiseMinTimeout(creationPromise, 500).then(response => {
        this.$timeout(() => {
          this.$location.path('/automations/' + response.message.id, false).search({});
        }, 300)
      });
    });
  }

  onDeleteSuccess(message) {
    this.messages.splice(this.messages.indexOf(message), 1);
    this.$notifier.green('Deleted.');
    this.messageCounts = this.buildMessageCounts();
  }

  deleteMessage(message) {
    if(confirm('Are you sure you want to delete this automation?')) {
      this.$resourceManager.request('messages', 'delete', {id: message.id}).then(() => {
        this.onDeleteSuccess(message);
      });
    }
  }

  activateMessage(message) {
    if (confirm('Are you sure you want to activate this email automation?')) {
      this.$resourceManager.request('messages', 'start', {id: message.id}).then(function() {
        message.activated = true;
      });
    }
  }

  deactivateMessage(message) {
    if (confirm('Are you sure you want to stop this automation from sending?')) {
      this.$resourceManager.request('messages', 'stop', {id: message.id}).then(function() {
        message.activated = false;
      });
    }
  }

  duplicateMessage(message) {
    if(confirm('Are you sure you want to duplicate this automation?')) {
      this.$resourceManager.request('messages', 'duplicate', {id: message.id}).then(response => {
        this.$location.path("/automations/" + response.message.id);
      });
    }
  }

}

_Controller.$inject = ['$resourceManager', '$notifier', '$location', '$opportunitiesAvailable', '$message', '$timeout'];

export const messageList = {
  controller: _Controller,
  template: require("./messageList.html")
};
class _Controller {
  constructor ($meta, $timeout) {
    this.$meta = $meta;
    this.$timeout = $timeout;
    this.loaded = false;
  }

  $onInit() {
    this.$timeout(() => {
      this.loaded = true;
    }, 100);
  }
}

_Controller.$inject = ['$meta', '$timeout'];

export const account = {
  controller: _Controller,
  template: require('./account.html')
};

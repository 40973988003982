class _Controller {

  constructor($resourceManager) {
    this.$resourceManager = $resourceManager;
  }

  cancelSubscription () {
    let answer = confirm('Are you sure you want to cancel your subscription? When your current billng period expires your emails will be paused.');
    if (!answer) return;
    this.$resourceManager.request('users', 'cancel_subscription', {id: 'current'}).then(() => {
      window.location = window.location;
    });
  }
}

_Controller.$inject = ['$resourceManager'];

export const cancelSubscription = {
  controller: _Controller,
  template: `
    <div class='sub-section'>
      <label class='label sub-section-header'>Cancel subscription</label>
      <div>
        <p>No longer send emails from Onboard. </p>
        <button class='delete-standard update-card-save' ng-click='$ctrl.cancelSubscription()'>Cancel</button>
      </div>
    </div>
  `,
};



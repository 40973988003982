import "./textField.scss"

export const textField = {
  template: `
    <div class='form-row'>
      <label ng-class='{"has_error": !!$ctrl.error}'>
        <span ng-transclude></span>
        <span class='error fade-down' ng-if='$ctrl.error'>({{$ctrl.error}})</span>
        <input type="text" ng-change='$ctrl.error = null; $ctrl.hasError = null' ng-model='$ctrl.model'>
      </label>
    </div>
  `,
  bindings: {
    model: '=',
    error: '=',
  },
  transclude: true
}
export const MessageHelper = ($resourceManager, $targetingOptions, $q) => {
  function _MessageHelper(message) {
    if (!message.loading) {
      if (message.opportunity_type)
        message.settings.targeting = $targetingOptions.getSelectedOption(
          message.opportunity_type,
          message.settings.targeting
        );
      message.settings.plans = message.settings.plans || [];
      message.settings.products = message.settings.products || [];
    }

    message.save = () => {
      return $q((resolve, reject) => {
        const _message = angular.copy(message);

        $resourceManager
          .save("messages", { id: message.id }, _message)
          .then(response => {
            if (message.id === null) message.id = response.message.id;
            resolve(response);
          })
          .catch(reject);
      });
    };

    return message;
  }

  return _MessageHelper;
};

MessageHelper.$inject = ["$resourceManager", "$targetingOptions", "$q"];

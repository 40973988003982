export const bigNumber = function() {

  var numberWithCommas = function(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return function(text) {
    var number = parseInt(text);
    if (number > 0) {
      var abs = Math.abs(number);
      if (abs >= Math.pow(10, 12)) {
        number = (number / Math.pow(10, 12)).toFixed(2) + "t";
      } else if (abs < Math.pow(10, 12) && abs >= Math.pow(10, 9)) {
        number = (number / Math.pow(10, 9)).toFixed(2) + "b";
      } else if (abs < Math.pow(10, 9) && abs >= Math.pow(10, 6)) {
        number = (number / Math.pow(10, 6)).toFixed(2) + "m";
      } else if (abs < Math.pow(10, 6) && abs >= Math.pow(10, 4)) {
        number = (number / Math.pow(10, 3)).toFixed(1) + "k";
      } else if (abs < Math.pow(10, 4) && abs >= Math.pow(10, 3)) {
        number = numberWithCommas(number);
      }
      return number;
    } else {
      return 0;
    }
  };
};
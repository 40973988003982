export const shortTimeTargetingSummary = function (
  $targetingOptions,
  capitalizeFilter
) {
  return function (targeting, opportunity_type) {
    if (!opportunity_type) return "&mdash;";

    var units = $targetingOptions.getSelectedOptionText(
      opportunity_type,
      targeting
    );

    if (units.match(/after/)) {
      units = units.replace(" after", "");
      if (!units.match(/immediately/)) units = "+ " + units;
    }

    if (units.match(/before/)) {
      units = units.replace(" before", "");
      if (!units.match(/immediately/)) units = "- " + units;
    }

    return capitalizeFilter(units);
  };
};

shortTimeTargetingSummary.$inject = ["$targetingOptions", "capitalizeFilter"];

import "./activities.scss";

class _Controller {
  constructor($resourceManager, $message, $activityStore, $timeout) {
    this.$resourceManager = $resourceManager;
    this.$message = $message;
    this.$timeout = $timeout;
    this.$a = $activityStore;
  }

}

_Controller.$inject = ['$resourceManager', '$message', '$activityStore', '$timeout'];

export const activities = {
  controller: _Controller,
  template: require('./activities.html'),
  bindings: {
    messageId: '<'
  }
}
import "./messages.scss";
import "./messages_analytics.scss";
import "./preview.scss";
import "./tinymce.scss";
import "./switch.scss";

import { messages } from "./messages.component.js";
import { messageList } from "./messageList.component.js";
import { messageNew } from "./messageNew.component.js";
import { messageShow } from "./messageShow.component.js";
import { messageActivity } from "./messageActivity.component.js";
import { $message } from "./message.service.js";
import { $activityStore } from "./activityStore.service.js";
import { preview } from "./preview/preview.component.js";
import { targetingPreview } from "./targeting_preview/targetingPreview.component.js";
import { activities } from "./activities/activities.component.js";
import { triggerSelector } from "./trigger_selector/triggerSelector.component.js";
import { planSelector } from "./plan_selector/planSelector.component.js";
import { productSelector } from "./product_selector/productSelector.component.js";
import { refreshButton } from "./refresh_button/refreshButton.component.js";
import { senderSelector } from "./sender_selector/senderSelector.component.js";
import { bccSelector } from "./bcc_selector/bccSelector.component.js";
import { advancedOptions } from "./advanced_options/advancedOptions.component.js";
import { automationFilter } from "./automation_filter/automationFilter.component.js";
import { emailThumbnail } from "./email_thumbnail/emailThumbnail.component.js";
import { emailEditor } from "./email_editor/emailEditor.component.js";
import { $scheduledStore } from "./scheduled/scheduledStore.service.js";
import { scheduled } from "./scheduled/scheduled.component.js";

export const MESSAGES_MODULE = angular.module("messages", []);

MESSAGES_MODULE.component("messages", messages);
MESSAGES_MODULE.component("messageList", messageList);
MESSAGES_MODULE.component("messageShow", messageShow);
MESSAGES_MODULE.component("messageActivity", messageActivity);
MESSAGES_MODULE.component("messageNew", messageNew);
MESSAGES_MODULE.service("$message", $message);
MESSAGES_MODULE.service("$activityStore", $activityStore);
MESSAGES_MODULE.component("preview", preview);
MESSAGES_MODULE.component("targetingPreview", targetingPreview);
MESSAGES_MODULE.component("activities", activities);
MESSAGES_MODULE.component("triggerSelector", triggerSelector);
MESSAGES_MODULE.component("senderSelector", senderSelector);
MESSAGES_MODULE.component("bccSelector", bccSelector);
MESSAGES_MODULE.component("planSelector", planSelector);
MESSAGES_MODULE.component("productSelector", productSelector);
MESSAGES_MODULE.component("refreshButton", refreshButton);
MESSAGES_MODULE.component("advancedOptions", advancedOptions);
MESSAGES_MODULE.component("automationFilter", automationFilter);
MESSAGES_MODULE.component("emailThumbnail", emailThumbnail);
MESSAGES_MODULE.component("emailEditor", emailEditor);
MESSAGES_MODULE.service("$scheduledStore", $scheduledStore);
MESSAGES_MODULE.component("scheduled", scheduled);

const states = [
  {
    name: "messages",
    component: "messages",
    parent: "app",
    section: "messages",
    authenticate: true
  },
  {
    name: "messageList",
    component: "messageList",
    url: "/automations",
    title: "Automations | Onboard",
    parent: "app",
    section: "messages"
  },
  // {
  //   name: 'messageNew',
  //   component: 'messageNew',
  //   url: '/automations/new',
  //   title: 'Create new automations | Onboard',
  //   parent: 'app',
  //   section: 'messages',
  // },
  {
    name: "messageShow",
    component: "messageShow",
    url: "/automations/:id",
    title: "Edit automations | Onboard",
    parent: "app",
    section: "messages"
  },
  {
    name: "messageActivity",
    component: "messageActivity",
    url: "/automations/:id/activity",
    title: "View activity | Onboard",
    parent: "app",
    section: "messages"
  }
];

MESSAGES_MODULE.config([
  "$uiRouterProvider",
  function($uiRouter) {
    states.forEach(state => $uiRouter.stateRegistry.register(state));
  }
]);

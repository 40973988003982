export const $opportunitiesCount = ($http, $q, $opportunitiesAvailable) => {
  var opCount = {};

  opCount.all = function () {
    var url = '/api/opportunities/count';
    return $q(function (resolve) {
      $http.get(url).then(function (result) {
        var progress = {};
        _.each(result.data, function (count, type) {
          var convertedType = $opportunitiesAvailable.convertType(type);
          progress[convertedType] = count;
        });
        resolve(progress);
      });
    });
  };

  return opCount;
};

$opportunitiesCount.$inject = ['$http', '$q', '$opportunitiesAvailable'];
export const stripeCard = ($resourceManager, $q, $timeout) => {

  return {
    restrict: 'E',
    scope: {
      type: '@'
    },
    template: `
    <form type='{{type}}' action="/ignore" method="post">
      <div class="card-element"></div>
      <div class="card-errors" role="alert"></div>
      <div class="form-row submit-row">
        <async-button icon='card' loading='loading' complete='complete' complete-text='Complete' submit='true'>
          Confirm
        </async-button>
      </div>
    </form>`,
    link: function (scope, element) {


      var stripe = Stripe(__STRIPE_PUBLISHABLE_KEY);
      var elements = stripe.elements();
      scope._token = null;

      scope.complete = false;
      scope.loading = false;

      scope.card = elements.create('card');

      scope.card.mount($('.card-element', element)[0]);

      element.on('submit', function (event) {
        event.preventDefault();
        setupIntent()
          .then(authenticatePayment)
          .then(subscribe)
          .then(refreshPage)
          .catch(error => {
            if (error && error.message) {
              var errorElement = $('.card-errors', element)[0];
              errorElement.textContent = error.message;
            }
            scope.loading = false;
            scope.complete = false;
          });
      });

      const refreshPage = () => {
        scope.complete = true;
        $timeout(() => {
          const param = (scope.type == "create") ? "?subscribed=true" : "";
          window.location.href = `${window.location.href}${param}`;
        }, 1500)
      };

      const setupIntent = token => {
        scope.loading = true;
        return $q((resolve, reject) => {
          $resourceManager.request('users', 'setup_intent', {id: 'current'}).then(resolve);
        });
      }
      
      const authenticatePayment = intent => {
        return $q((resolve, reject) => {
          scope.intent = intent;
          stripe.handleCardSetup(intent.client_secret, scope.card).then(result => {
            if (result.error) {
              reject(result.error)
            } else {
              resolve();
            }
          });
        });
      }

      const subscribe = () => {
        return $q((resolve, reject) => {
          $resourceManager.request('users', 'subscribe', {id: 'current'}, {intent_id: scope.intent.id}).then(resolve);
        })
      }

    }
  };
};

stripeCard.$inject = ['$resourceManager', '$q', '$timeout'];
/**
 * This file imports the third party library dependencies, then creates the angular module "demo"
 * and exports it.
 */
// External dependencies
import * as angular from "angular";
import uiRouter from "@uirouter/angularjs";
import { StickyStatesPlugin } from '@uirouter/sticky-states';
import { DSRPlugin } from '@uirouter/dsr';
import * as $resource from 'angular-resource';
import * as ngCookies from 'angular-cookies';
import * as ngSanitize from 'angular-sanitize';
import * as angularMoment from "angular-moment";
import * as dirPagination from "angular-utils-pagination";
import * as angularSpinkit from "angular-spinkit";

require('AngularDevise/lib/devise-min');
// require('jquery-base64');
require('iso-currency/dist/isoCurrency.min');
require('ob-daterangepicker/dist/scripts/ob-daterangepicker');
require('angular-animate/angular-animate.min');

import "chart.js/dist/Chart.min.js";

import tinymce from 'tinymce/tinymce';

import 'tinymce/plugins/link';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/textcolor';
import './editor/button.tinyMCEPlugin';
import './editor/palette.tinyMCEPlugin';
import './editor/link.tinyMCEPlugin';
import './editor/title.tinyMCEPlugin';
import 'tinymce/plugins/code';

require('angular-ui-tinymce/dist/tinymce.min');

import 'jquery-minicolors-webpack/jquery.minicolors';
import 'jquery-minicolors-webpack/jquery.minicolors.png';
import 'angular-minicolors/angular-minicolors';
import 'angular-bind-html-compile';

// import 'codemirror/lib/codemirror.css';
import './uiCodemirror.module.js';

import { ActionCable } from 'actioncable';

import { ngWebSocket } from 'angular-websocket';
const ngActionCable = require("exports-loader?ngActionCable!angular-actioncable");

import { GLOBAL_MODULE } from './global.module';
import { SALES_MODULE } from '../sales/sales.module';
import { APP_MODULE } from '../app/app.module';
import { FILTERS_MODULE } from './filters/filters.module';
import { MESSAGES_MODULE } from "../app/messages/messages.module";
import { ACCOUNT_MODULE } from "../app/account/account.module";
import { DASHBOARD_MODULE } from "../app/dashboard/dashboard.module";
import { ELEMENTS_MODULE } from "./elements/elements.module";

_.capitalize = function (string) {
  return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
};

export const ngmodule = angular.module("onboardApp", [
  uiRouter,
  'ngActionCable',
  $resource,
  ngCookies,
  ngSanitize,
  angularMoment,
  dirPagination,
  'angular-spinkit',
  'Devise',
  'isoCurrency',
  'obDateRangePicker',
  'ui.tinymce',
  'ngAnimate',
  'minicolors',
  'ui.codemirror',
  'angular-bind-html-compile',

  GLOBAL_MODULE.name,
  SALES_MODULE.name,
  APP_MODULE.name,
  FILTERS_MODULE.name,
  MESSAGES_MODULE.name,
  ACCOUNT_MODULE.name,
  DASHBOARD_MODULE.name,
  ELEMENTS_MODULE.name,

]);

ngmodule.config(['$uiRouterProvider', '$locationProvider', '$provide', ($uiRouter, $locationProvider, $provide) => {

  $locationProvider.hashPrefix('');
  $uiRouter.plugin(StickyStatesPlugin);
  $uiRouter.plugin(DSRPlugin);

  $locationProvider.html5Mode({
    enabled: true,
    requireBase: false
  });

  // // Show the UI-Router Visualizer
  // import("@uirouter/visualizer").then(module => $uiRouter.plugin(module.Visualizer));

  $provide.decorator('$location', ["$delegate", function($delegate) {

    $delegate.addParams = object => {
      let params = $delegate.search();
      angular.forEach(object, (value, key) => {
        params[key] = value;
      });
      return $delegate.search(params);
    };

    $delegate.removeParams = keys => {
      keys = (Array.isArray(keys)) ? keys : [keys];
      let params = $delegate.search();
      keys.forEach(key => delete params[key]);
      return $delegate.search(params);
    };

    return $delegate;
  }]);

}])

// .run(['ActionCableConfig', function (ActionCableConfig) {
//   ActionCableConfig.autoStart = false;
// }]);

export const tickboxesFactory = () => (available, item_id) => {
  return {
    tickboxes: available,

    targetAll: true,
    setSelected: function(comparison) {
      if (!_.isArray(available) || available.length === 0) return;
      this.tickboxes = available.map(function(item) {
        item.selected = comparison && _.contains(comparison, item[item_id]);
        return item;
      });
      this.targetAll = !comparison || comparison.length === 0;
    },

    onChangeTargetAll: function() {
      this.deselectAll();
      this.targetAll = true;
      // if (this.targetAll)
      // if (!this.targetAll && this.tickboxes.length < 1) this.targetAll = true;
    },

    deselectAll: function() {
      angular.forEach(this.tickboxes, function(item) {
        item.selected = false;
      });
    },

    deselectTargetAll: function() {
      angular.forEach(
        this.tickboxes,
        function(item) {
          if (item.selected) this.targetAll = false;
        }.bind(this)
      );
    },

    getTargetedIds: function() {
      var targetedIds = this.tickboxes.reduce(function(array, item) {
        if (item.selected) array.push(item[item_id]);
        return array;
      }, []);
      return targetedIds.length > 0 ? targetedIds : [];
    }
  };
};

tickboxesFactory.$inject = [];

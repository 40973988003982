import "./productSelector.scss";

class _Controller {
  constructor(
    $opportunitiesAvailable,
    $productTickboxes,
    $targetingOptions,
    UserService,
    $message,
    $availableProducts
  ) {
    this.$opportunitiesAvailable = $opportunitiesAvailable;
    this.expanded = false;
    this.$message = $message;
    this.$productTickboxes = $productTickboxes;
    this.$availableProducts = $availableProducts;
    this.user = UserService.user;
  }

  showMenu(e) {
    this.expanded = true;
  }

  hideMenu() {
    this.expanded = false;
  }

  updateTargeting() {
    this.$message.message.settings.products = this.$productTickboxes.getTargetedIds();
  }

  onChangeTargetAll() {
    this.$message.setTargetingDirty();
    this.$productTickboxes.onChangeTargetAll();
    this.updateTargeting();
    this.onChange();
  }

  onTickPlan() {
    this.$message.setTargetingDirty();
    this.$productTickboxes.deselectTargetAll();
    this.updateTargeting();

    if (!this.$productTickboxes.getTargetedIds())
      this.$productTickboxes.targetAll = true;
    this.onChange();
  }

  refreshProducts() {
    this.$availableProducts.refresh();
  }
}

_Controller.$inject = [
  "$opportunitiesAvailable",
  "$productTickboxes",
  "$targetingOptions",
  "UserService",
  "$message",
  "$availableProducts",
];

export const productSelector = {
  controller: _Controller,
  template: require("./productSelector.html"),
  bindings: {
    onChange: "&",
  },
};

export const AnalyticQueueItem = ($q) => {
  function _AnalyticQueueItem (metric, type, id, params) {
    var queueItem = {
      metric: metric,
      type: type,
      id: id,
      params: params,
      defer: $q.defer()
    };

    return queueItem;
  }

  return _AnalyticQueueItem;
};

AnalyticQueueItem.$inject = ['$q'];

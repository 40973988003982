class _Controller {
  constructor(Auth, $location, $timeout, userErrors) {
    this.Auth = Auth;
    this.$location = $location;
    this.$timeout = $timeout;
    this.errors = userErrors({doValidate: ['email', 'password']});
    this.user = {
      email: null,
      password: null
    };
    this.login = {};
    this.submitted = false;
  }

  onSuccess() {
    this.$location.path('/dashboard');
  }

  submit() {

    this.submitted = true;
    
    this.errors.reset();

    if (!this.errors.any(this.user)) {
      this.submitted = false;
      return this.errors.notify();
    }

    this.Auth.login(this.user, { headers: { 'X-HTTP-Method-Override': 'POST'} })
      .then(this.onSuccess.bind(this))
      .catch(this.errors.notify)
      .finally(() => { this.submitted = false; });
  }

}

_Controller.$inject = ['Auth', '$location', '$timeout', 'userErrors'];

export const login = {
  controller: _Controller,
  template: require('./login.html')
};
import "./designColors.scss";

class _Controller {

  constructor($resourceManager, $notifier, UserService, $emailDesign) {
    this.$resourceManager = $resourceManager;
    this.user = UserService.user;
    this.$notifier = $notifier;
    this.$emailDesign = $emailDesign;

    this.colors = {
      button: angular.copy($emailDesign.buttonColor),
      text: angular.copy($emailDesign.textColor),
      link: angular.copy($emailDesign.linkColor),
      h1: angular.copy($emailDesign.h1Color),
      h2: angular.copy($emailDesign.h2Color),
    };

    this.colorPickerConfig = {
      theme: 'bootstrap',
      position: 'top right'
    };
  }

  updateColors() {

    this.user.settings.branding = {
      buttonColor: this.colors.button,
      linkColor:   this.colors.link,
      textColor:   this.colors.text,
      h1Color:   this.colors.h1,
      h2Color:   this.colors.h2,
    };

    this.$resourceManager.request('users', 'update_settings', {id: 'current'}, {settings: this.user.settings}).then(res => {
      this.$notifier.green("Saved.");
      this.$emailDesign.set(this.colors);
    }).catch(err => {
      this.$notifier.red('Something went wrong.');
    });

  }

  isValid() {
    return (
      /^#[0-9A-F]{6}$/i.test(this.colors.button) &&
      /^#[0-9A-F]{6}$/i.test(this.colors.link) &&
      /^#[0-9A-F]{6}$/i.test(this.colors.text) &&
      /^#[0-9A-F]{6}$/i.test(this.colors.h1) &&
      /^#[0-9A-F]{6}$/i.test(this.colors.h2) &&
      !(
        this.colors.link.toLowerCase() === this.$emailDesign.linkColor.toLowerCase() &&
        this.colors.button.toLowerCase() === this.$emailDesign.buttonColor.toLowerCase() &&
        this.colors.text.toLowerCase() === this.$emailDesign.textColor.toLowerCase() &&
        this.colors.h1.toLowerCase() === this.$emailDesign.h1Color.toLowerCase() &&
        this.colors.h2.toLowerCase() === this.$emailDesign.h2Color.toLowerCase()
      )
    );
  }

  save() {
    this.updateColors();
  }

}

_Controller.$inject = ['$resourceManager', '$notifier', 'UserService', '$emailDesign'];

export const designColors = {
  controller: _Controller,
  template: require('./designColors.html')
};

const startJobs = (_this) => () => {
  const id = _this.$location.path().match(/\/automations\/([0-9]+)/)[1];
  _this.$scheduledStore.request(id);
};

class _Controller {
  constructor(
    $location,
    $timeout,
    $message,
    UserService,
    messageAnalytics,
    analyticsDateFilter,
    $activityStore,
    $modal,
    ScrollLock,
    $resourceManager,
    $scheduledStore,
    $cableConnection
  ) {
    this.$location = $location;
    this.$resourceManager = $resourceManager;
    this.$timeout = $timeout;
    this.user = UserService.user;
    this.$message = $message;
    this.$activityStore = $activityStore;
    this.period = { start: moment().subtract(30, "days"), end: moment() };
    this.messageAnalytics = messageAnalytics;
    this.analyticsDateFilter = analyticsDateFilter;
    $modal.new(".debug-modal");
    this.$modal = $modal;
    this.$scheduledStore = $scheduledStore;
    this.$cableConnection = $cableConnection;
  }

  $onInit() {
    const id = this.$location.path().match(/\/automations\/([0-9]+)/)[1];
    this.$message.get(id).then(() => {
      this.updateActivities();
    });
    this.$cableConnection.onConnected(startJobs(this));
  }

  updateActivities() {
    this.$activityStore.setupAndRequest(this.$message.message, this.period);
    startJobs(this);
  }

  newDebug() {
    this.debugEventId = null;
    this.debugResponse = null;
    this.$modal.show();
  }

  closeDebugModal() {
    this.$modal.hide();
  }

  debugMessage() {
    this.$resourceManager
      .request("messages", "debug", {
        id: this.$message.message.id,
        event_id: this.debugEventId,
      })
      .then((res) => {
        this.debugResponse = JSON.stringify(res, undefined, 2);
      });
  }
}

_Controller.$inject = [
  "$location",
  "$timeout",
  "$message",
  "UserService",
  "messageAnalytics",
  "analyticsDateFilter",
  "$activityStore",
  "$modal",
  "ScrollLock",
  "$resourceManager",
  "$scheduledStore",
  "$cableConnection",
];

export const messageActivity = {
  controller: _Controller,
  template: require("./messageActivity.html"),
};

export const targetingSummary = function($availablePlans, $availableProducts) {
  return function(items, type) {
    if (!items || items.length === 0) {
      if (type === "plan") {
        return "any subscription plan";
      }

      return "any product";
    }

    const available =
      type === "plan" ? $availablePlans.items : $availableProducts.items;

    const names = _.chain(items)
      .map(item => _.findWhere(available, { [`${type}_id`]: item }))
      .reject(_.isUndefined)
      .map(item => item[`${type}_name`])
      .value();

    return names.join(", ").replace(/\, ([^, ]*)$/, " or " + "$1");
  };
};

targetingSummary.$inject = ["$availablePlans", "$availableProducts"];

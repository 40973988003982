export const $message = (
  $q,
  $resourceManager,
  $opportunitiesAvailable,
  $messageHelper,
  $emailHelper,
  $notifier,
  $location,
  $planTickboxes,
  $productTickboxes
) => {
  let $message = {
    message: { loading: true },
    email: { loading: true },
    opportunity: {},
    targetingChanged: false
  };

  $message.setTargetingDirty = () => {
    $message.targetingChanged = true;
  };

  $message.setOpportunityType = type => {
    $message.message.opportunity_type = type;
    $message.opportunity = $opportunitiesAvailable.get(type);
  };

  $message.setTargetingClean = () => {
    $message.targetingChanged = false;
  };

  $message.reset = () => {
    $message.message = { loading: true };
    $message.email = { loading: true };
    $message.opportunity = {};
    $message.targetingChanged = false;
  };

  $message.updateOpportunity = () => {
    $message.opportunity = $opportunitiesAvailable.get(
      $message.message.opportunity_type
    );

    $planTickboxes.setSelected($message.message.settings.plans);
    $productTickboxes.setSelected($message.message.settings.products);
  };

  $message.new = type => {
    const params = !!type ? { opportunity_type: type } : {};
    return $message.build([
      $resourceManager.request("messages", "new", params),
      $resourceManager.request("emails", "new")
    ]);
  };

  $message.get = id => {
    return $message.build([
      $resourceManager.request("messages", "get", { id }),
      $resourceManager.request("messages", "email", { id })
    ]);
  };

  $message.build = promises => {
    return $q((resolve, reject) => {
      $q.all(promises)
        .then(responses => {
          let [messageResponse, emailResponse] = responses;
          let message = $messageHelper(messageResponse.message);
          let opportunity = $opportunitiesAvailable.get(
            message.opportunity_type
          );
          let email = $emailHelper(
            emailResponse.email,
            message.opportunity_type
          );
          $message.message = message;
          $message.opportunity = opportunity;
          $message.email = email;

          $planTickboxes.setSelected(message.settings.plans);
          $productTickboxes.setSelected(message.settings.products);

          resolve($message);
        })
        .catch(reject);
    });
  };

  $message.saveNew = () => {
    return $q((resolve, reject) => {
      $message.email.save().then(response => {
        $message.message.email_id = response.email.id;
        $message.message.save().then(response => {
          return resolve(response);
        });
      });
    });
  };

  $message.save = (onSave, isQuiet) => {
    $message.message.save().then(() => {
      $message.email.save().then(response => {
        $message.setTargetingClean();
        if (!isQuiet) $notifier.green("Saved.");
        if (onSave) onSave(response);
      });
    });
  };

  $message.activate = () => {
    $resourceManager
      .request("messages", "start", { id: $message.message.id })
      .then(() => {
        $message.message.activated = true;
        $message.message.state = "started";
      });
  };

  $message.deactivate = () => {
    $resourceManager
      .request("messages", "stop", { id: $message.message.id })
      .then(() => {
        $message.message.activated = false;
        $message.message.state = "stopped";
      });
  };

  return $message;
};

$message.$inject = [
  "$q",
  "$resourceManager",
  "$opportunitiesAvailable",
  "$messageHelper",
  "$emailHelper",
  "$notifier",
  "$location",
  "$planTickboxes",
  "$productTickboxes"
];

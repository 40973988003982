export const metricLabel = () => {
  return {
    restrict: 'E',
    scope: { label: '@', calloutContent: '@' },
    template: `
      <label class='metric-label'>
        {{label}}
        <div class='info-icon' callout='{{calloutContent }}' callout-direction='top' callout-behaviour='hover'>
            <span class='metric-tip'></span>
          </div>
      </label>`
  };
};
export const eventType = function() {
  return function (input) {
    var types = {
      'sent': 'Sent at',
      'delivered': 'Received at',
      'opened': 'Opened at',
      'clicked': 'Clicked at',
      'dropped': 'Dropped at',
      'bounced': 'Bounced at'
    };
    return (types.hasOwnProperty(input)) ? types[input] : input;
  };
};

export const $resourceRoutes = () => {
  // Default routes created for key
  //
  //   'get'   : { method: 'GET' }
  //   'save'  : { method: 'POST'}
  //   'query' : { method: 'GET', isArray: true}
  //   'remove': { method: 'DELETE'}
  //   'delete': { method: 'DELETE'}

  var routes = {
    opportunties: {
      scheduled: {
        method: "GET",
        url: "/api/opportunities/scheduled",
        isArray: false
      }
    },
    analytics: {
      daily: { method: "GET", url: "/api/opportunities/daily", isArray: true }
    },
    plans: {
      query: { method: "GET", isArray: false },
      refresh: { method: "GET", url: "/api/plans/refresh", isArray: false }
    },
    products: {
      query: { method: "GET", isArray: false },
      refresh: { method: "GET", url: "/api/products/refresh", isArray: false }
    },
    admin_users: {
      query: { method: "GET", isArray: false, url: "/api/admin/users" },
      lock: { method: "PUT", url: "/api/admin/users/:id/lock" },
      unlock: { method: "PUT", url: "/api/admin/users/:id/unlock" },
      delete: { method: "DELETE", url: "/api/admin/users/:id" }
    },
    messages: {
      query: { method: "GET", isArray: false },
      new: { method: "GET", url: "/api/messages/new" },
      update: { method: "PUT", url: "/api/messages/:id" },
      start: { method: "PATCH", url: "/api/messages/:id/start" },
      stop: { method: "PATCH", url: "/api/messages/:id/stop" },
      duplicate: { method: "POST", url: "/api/messages/:id/duplicate" },
      activity: { method: "GET", url: "/api/messages/:id/activity" },
      debug: { method: "GET", url: "/api/messages/:id/debug" },
      targeting_preview: {
        method: "POST",
        url: "/api/messages/targeting_preview"
      },
      email: { method: "GET", url: "/api/messages/:id/email" }
    },
    senders: {
      current: { method: "GET", url: "/api/senders/current" },
      query: { method: "GET", isArray: false },
      update: { method: "PUT", url: "/api/senders/:id" },
      resend_confirmation: {
        method: "PATCH",
        url: "/api/senders/:id/resend_confirmation"
      }
    },
    emails: {
      update: { method: "PUT", url: "/api/emails/:id" },
      new: { method: "GET", url: "/api/emails/new" },
      test: { method: "POST", url: "/api/emails/test" },
      preview: { method: "POST", url: "/api/emails/preview" }
    },
    users: {
      update_settings: { method: "PUT", url: "/api/users/:id/update_settings" },
      update_password: { method: "PUT", url: "/api/users/:id/update_password" },
      setup_intent: { method: "POST", url: "/api/users/:id/setup_intent" },
      subscribe: { method: "POST", url: "/api/users/:id/subscribe" },
      cancel_subscription: {
        method: "DELETE",
        url: "/api/users/:id/cancel_subscription"
      },
      subscription: { method: "GET", url: "/api/users/:id/subscription" },
      uncancel_subscription: {
        method: "PUT",
        url: "/api/users/:id/uncancel_subscription"
      },
      renew_subscription: {
        method: "PUT",
        url: "/api/users/:id/renew_subscription"
      },
      customer: { method: "GET", url: "/api/users/:id/customer" },
      stripe_account: { method: "GET", url: "/api/users/:id/stripe_account" },
      documents: { method: "GET", url: "/api/users/:id/documents" }
    },
    passwords: {
      reset: { method: "POST", url: "/passwords/reset" },
      update: { method: "PUT", url: "/passwords/:reset_password_token" }
    }
  };

  return routes;
};

export const OpportunityAnalytics = ($analyticsManager, $q) => {
  function _OpportunityAnalytics (opportunity) {
    var analytics = { opportunity: opportunity, name: 'opportunityAnalytics' };

    analytics.resolve = function () {
      return $q(function (resolve, reject) {
        if (opportunity.status === 'building') {
          // will only get analytics if the opportunity is ready
          resolve(null);
        } else {
          var promise = $analyticsManager.request({method: 'opportunity', active: (opportunity.status === 'activated')});
          promise.then(function (response) {
            var value = (response) ? response.data : 0;
            return resolve(value);
          }, function (reason) {
            reject(reason);
          });
        }
      });
    };

    return analytics;
  }

  return _OpportunityAnalytics;
};

OpportunityAnalytics.$inject = ['$analyticsManager', '$q'];
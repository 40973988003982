class _Controller {

  constructor() {}
}

_Controller.$inject = [];

export const updateCard = {
  controller: _Controller,
  template: `
    <div class='sub-section'>
      <label class='label sub-section-header'>Enter new card details</label>
      <stripe-card type='update'></stripe-card>
    </div>
  `,
};
class _Controller {
  constructor ($resourceManager, $notifier, $location, $timeout, $window, userErrors) {
    this.reset_password_token = $location.search().reset_password_token;
    this.$notifier = $notifier;
    this.$resourceManager = $resourceManager;
    this.$location = $location;
    this.$timeout = $timeout;
    this.$window = $window;
    this.submitted = false;
    this.errors = userErrors({doValidate: ['password', 'password_confirmation']});
    this.user = {
      password: null,
      password_confirmation: null
    };
  }

  onSuccess() {
    this.submitted = false;
    this.$notifier.green('Changed.');
    this.$timeout(() => {
      this.$location.path('/dashboard');
    }, 1000);
  }

  submit() {
    this.submitted = true;

    this.errors.reset();

    if (!this.errors.any(this.user)) {
      this.submitted = false;
      return this.errors.notify();
    }

    let reset_password_token = this.reset_password_token;
    this.$resourceManager.request('passwords', 'update', {reset_password_token}, this.user)
      .then(this.onSuccess.bind(this))
      .catch(this.errors.notify)
      .finally(() => { this.submitted = false; });

  }

}

_Controller.$inject = ['$resourceManager', '$notifier', '$location', '$timeout', '$window', 'userErrors'];

export const passwordEdit = {
  controller: _Controller,
  template: require('./passwordEdit.html'),
};
export const EmailHelper = ($resourceManager, $emailTemplates, $q) => {
  function _EmailHelper (email, opportunityType, force) {
    $emailTemplates.populateSubject(email, opportunityType, force);

    email.save = () => {
      return $q((resolve, reject) => {
        const _email = angular.copy(email);
        if (!_email.subject) _email.subject = "";
        $resourceManager.save('emails', {id: email.id}, _email).then(response => {
          if (email.id === null) email.id = response.email.id;
          resolve(response);
        }).catch(reject);
      });
    };

    email.preview = function (onComplete) {
      var promise = $resourceManager.request('emails', 'preview', null, {subject: email.subject, body: email.body, body_type: email.body_type});
      promise.then(onComplete);
    };

    email.test = function (testEmails, onComplete) {
      var promise = $resourceManager.request('emails', 'test', null, {test_emails: testEmails, subject: email.subject, body: email.body, body_type: email.body_type});
      promise.then(onComplete);
    };

    return email;
  }

  return _EmailHelper;
};

EmailHelper.$inject = ['$resourceManager', '$emailTemplates', '$q'];
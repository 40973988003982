import "./testSendModal.scss";

class _Controller {
  constructor($testSendModal, $message, $notifier, $timeout) {
    this.$message = $message;
    this.$notifier = $notifier;
    this.$testSendModal = $testSendModal;
    this.testSendEmail = null;
    this.submitting = false;
    this.complete = false;
    this.$timeout = $timeout;
  }

  sendTestEmail() {
    if (!this.testSendEmail) return false;
    this.submitting = true;
    this.$message.email.test(this.testSendEmail, () => {
      this.complete = true;
      this.$timeout(() => {
        this.$testSendModal.hide();
        this.submitting = false;
        this.complete = false;
      }, 1500)
    });
  }
}

_Controller.$inject = ['$testSendModal', '$message', '$notifier', '$timeout'];

export const testSendModal = {
  controller: _Controller,
  template: require('./testSendModal.html')
};
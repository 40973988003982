import "./dashboard.scss";
import { dashboard } from "./dashboard.component.js";

export const DASHBOARD_MODULE = angular.module("dashboard", []);

DASHBOARD_MODULE.component("dashboard", dashboard);

const states = [
  {
    name: "dashboard",
    url: "/dashboard",
    component: "dashboard",
    title: "Dashboard | Onboard",
    parent: "app",
    section: "dashboard",
  },
  {
    name: "summary",
    url: "/summary",
    redirectTo: "dashboard",
  },
];

DASHBOARD_MODULE.config([
  "$uiRouterProvider",
  function ($uiRouter) {
    states.forEach((state) => $uiRouter.stateRegistry.register(state));
  },
]);

// Load global hooks here. Things like redirects, auth checks, loading bars etc.

import { $meta } from "./meta.service";
import { UserService } from "./user.service";
import { $redirects } from "./redirects.service";
import { $resourceManager } from "./resourceManager.service";
import { $resourceRoutes } from "./resourceRoutes.service";
import { $availablePlans } from "./availablePlans.service";
import { $availableProducts } from "./availableProducts.service";
import { $notifier } from "./notifier.service";
import { $opportunitiesAvailable } from "./opportunities/opportunitiesAvailable.service";
import { $opportunitiesCount } from "./opportunities/opportunitiesCount.service";
import { $opportunitiesStatus } from "./opportunities/opportunitiesStatus.service";
import { $targetingOptions } from "./targetingOptions.service";
import { $planTickboxes } from "./planTickboxes.service";
import { $productTickboxes } from "./productTickboxes.service";
import { $emailTemplates } from "./emailTemplates.service";
import { $advancedOptions } from "./advancedOptions.service";
import { $currentSender } from "./currentSender.service";
import { $analyticsManager } from "./analytics/analyticsManager.service";
import { $analyticsResource } from "./analytics/analyticsResource.service";
import { $cableConnection } from "./cableConnection.service";

import { ScrollLock } from "./scrollLock.factory";

import { MessageHelper } from "./factories/messageHelper.factory";
import { EmailHelper } from "./factories/emailHelper.factory";
import { tickboxesFactory } from "./factories/tickboxes.factory";
import { MessageAnalytics } from "./factories/analytics/messageAnalytics.factory";
import { OpportunityAnalytics } from "./factories/analytics/opportunityAnalytics.factory";
import { AnalyticQueueItem } from "./factories/analytics/analyticQueueItem.factory";
import { AnalyticQueue } from "./factories/analytics/analyticQueue.factory";
import { analyticsServiceController } from "./analytics/analyticsService.controller";

import { $connectModal } from "./modals/connectModal.service";
import { connectModal } from "./modals/connectModal.component.js";
import { $testSendModal } from "./modals/testSendModal.service";
import { testSendModal } from "./modals/testSendModal.component.js";

import { designColors } from "./design_colors/designColors.component.js";

import { dots } from "./dots/dots.directive.js";
import { dotItem } from "./dots/dotItem.directive.js";
import { analytics } from "./analytics/analytics.directive.js";
import { analyticsTotal } from "./analytics/analyticsTotal.directive";
import { analyticsRate } from "./analytics/analyticsRate.directive";
import { percentageCircle } from "./analytics/percentageCircle.directive";
import { metricLabel } from "./metricLabel.directive";
import { callout } from "./callout.directive";
import { stripeCard } from "./stripe/stripeCard.directive.js";
import { stripeForm } from "./stripeForm.component";

export const GLOBAL_MODULE = angular.module("global", []);

GLOBAL_MODULE.service("$meta", $meta);
GLOBAL_MODULE.service("UserService", UserService);
GLOBAL_MODULE.service("$resourceManager", $resourceManager);
GLOBAL_MODULE.service("$resourceRoutes", $resourceRoutes);
GLOBAL_MODULE.service("$availablePlans", $availablePlans);
GLOBAL_MODULE.service("$availableProducts", $availableProducts);
GLOBAL_MODULE.service("$notifier", $notifier);
GLOBAL_MODULE.service("$opportunitiesAvailable", $opportunitiesAvailable);
GLOBAL_MODULE.service("$opportunitiesCount", $opportunitiesCount);
GLOBAL_MODULE.service("$opportunitiesStatus", $opportunitiesStatus);
GLOBAL_MODULE.service("$targetingOptions", $targetingOptions);
GLOBAL_MODULE.service("$planTickboxes", $planTickboxes);
GLOBAL_MODULE.service("$productTickboxes", $productTickboxes);
GLOBAL_MODULE.service("$emailTemplates", $emailTemplates);
GLOBAL_MODULE.service("$advancedOptions", $advancedOptions);
GLOBAL_MODULE.service("$currentSender", $currentSender);
GLOBAL_MODULE.service("$analyticsManager", $analyticsManager);
GLOBAL_MODULE.service("$analyticsResource", $analyticsResource);
GLOBAL_MODULE.service("$cableConnection", $cableConnection);

GLOBAL_MODULE.controller(
  "analyticsServiceController",
  analyticsServiceController
);

GLOBAL_MODULE.factory("$messageHelper", MessageHelper);
GLOBAL_MODULE.factory("$emailHelper", EmailHelper);
GLOBAL_MODULE.factory("tickboxesFactory", tickboxesFactory);

GLOBAL_MODULE.factory("messageAnalytics", MessageAnalytics);
GLOBAL_MODULE.factory("opportunityAnalytics", OpportunityAnalytics);
GLOBAL_MODULE.factory("analyticQueueItem", AnalyticQueueItem);
GLOBAL_MODULE.factory("analyticQueue", AnalyticQueue);
GLOBAL_MODULE.factory("ScrollLock", ScrollLock);

GLOBAL_MODULE.directive("dots", dots);
GLOBAL_MODULE.directive("dotItem", dotItem);
GLOBAL_MODULE.directive("analytics", analytics);
GLOBAL_MODULE.directive("metricLabel", metricLabel);
GLOBAL_MODULE.directive("callout", callout);
GLOBAL_MODULE.directive("analyticsTotal", analyticsTotal);
GLOBAL_MODULE.directive("analyticsRate", analyticsRate);
GLOBAL_MODULE.directive("percentageCircle", percentageCircle);
GLOBAL_MODULE.directive("stripeCard", stripeCard);

GLOBAL_MODULE.component("connectModal", connectModal);
GLOBAL_MODULE.service("$connectModal", $connectModal);

GLOBAL_MODULE.component("testSendModal", testSendModal);
GLOBAL_MODULE.service("$testSendModal", $testSendModal);

GLOBAL_MODULE.component("designColors", designColors);
GLOBAL_MODULE.component("stripeForm", stripeForm);

GLOBAL_MODULE.service("$redirects", $redirects);
GLOBAL_MODULE.value("transitionChecker", { inProgress: false });
import { pageChangeAppHook } from "./pageChangeApp.hook";
GLOBAL_MODULE.run(pageChangeAppHook);

export const $cableConnection = ($rootScope, ActionCableChannel, ActionCableSocketWrangler, $timeout, $q) => {
  const service = {
    registeredFunctions: [],
    registerFunction: (func) => {
      service.registeredFunctions.push(func)
      return true;
    },
    onConnectedFunctions: [],
    onConnected: (func) => {
      if (service.connected) {
        func();
      } else {
        service.onConnectedFunctions.push(func);
      }
      return true;
    },
    waiting: true,
    queue: [],
    connected: false,
    connect: () => (
      $q(function (resolve, reject) {
        if (!ActionCableSocketWrangler.connected) {
          // ActionCableSocketWrangler.start(); // Don't call start or it breaks
          var consumer = new ActionCableChannel('ResponseChannel');
          consumer.subscribe(onReceiveMessage);
          consumer.onConfirmSubscription(() => {
            setTimeout(onConnected, 1000);
            resolve(true);
          });
        } else {
          resolve(true);
        }
      })
    )
  };

  const onConnected = () => {
    service.connected = true;
    service.onConnectedFunctions.forEach((func) => {
      try {
        func();
      } catch (e) {
        // noop
      }
    });
  };

  const onReceiveMessage = (message) => {
    service.registeredFunctions.forEach((func) => {
      try {
        func(message);
      } catch (e) {
        // noop
      }
    });

    // const writeToQueue = message => service.queue.push(message);

    // await $q.all(_.map(service.registeredFunctions, (func) => (
    //   $q((resolve, reject) => {
    //     try {
    //       func(message)
    //     } catch (e) {
    //       return reject(e);
    //     }
    //     return resolve();
    //   })
    // )));
  };

  // $rootScope.$watch(() => `${service.queue.length}-${service.waiting}`, async (newValue, oldValue) => {
  //   console.log(newValue)
  //   if (!!service.queue.length && service.waiting) {
  //     service.waiting = false;
  //     await onReceiveMessage(service.queue[0]);
  //     service.queue = service.queue.splice(1);
  //     service.waiting = true;
  //   }
  // })

  return service;
};

$cableConnection.$inject = ['$rootScope', 'ActionCableChannel', 'ActionCableSocketWrangler', '$timeout', '$q'];

export const $opportunitiesAvailable = () => {
  var opportunties = {
    types: {
      pre_dunning: "pre_dunning",
      cancellation_recovery: "cancellation_recovery"
    },
    available: {
      dunning_after: {
        id: "dunning_after",
        title: "Payment failed (dunning)",
        category: "payment",
        shortName: "Dunning",
        description: "Send this email when a customer's regular payment fails",
        buildingDesc: "Calculating revenue lost due to failed payments",
        detailedDesc:
          "<strong>Dunning emails</strong> are sent automatically after a customer's payment has failed, reminding them to update their payment details. You can set up multiple dunning emails that will trigger at different times. Onboard will automatically stop sending them when the customer updates their payment details.",
        activatedDesc: "<strong>reclaimed</strong> after failed payments",
        unit: "cents",
        targeting: "plans"
      },
      pre_dunning_before: {
        id: "pre_dunning_before",
        title: "Payment will fail (pre-dunning)",
        category: "payment",
        shortName: "Pre-dunning",
        description:
          "Send this email if a customer's card will expire before their next bill date",
        buildingDesc: "Analysing subscriptions with expiring cards",
        detailedDesc:
          "<strong>Pre-dunning emails</strong> are emails sent automatically to each customer a few days before their card details are due to expire, to remind them to update their details.",
        unit: "cents",
        activatedDesc: "<strong>rescued</strong> from expiring cards",
        timePeriod: "in the next 30 days",
        targeting: "plans"
      },
      invoice_receipt_after: {
        id: "invoice_receipt_after",
        title: "Invoice paid",
        category: "payment",
        shortName: "Invoice receipt",
        description:
          "Send this email when a customer pays their subscription invoice",
        buildingDesc: "Evaluating invoices",
        detailedDesc:
          "<strong>Invoice receipt emails</strong> are sent automatically when a customer completes a payment and is sent an invoice.",
        unit: "count",
        targeting: "plans"
      },
      charge_succeeded_after: {
        id: "charge_succeeded_after",
        title: "Charge succeeded",
        category: "payment",
        shortName: "Charge succeeded",
        description:
          "Send this email when a customer pays for a one-off charge outside of Stripe Billing/Subscriptions",
        buildingDesc: "Evaluating card updates",
        detailedDesc:
          "<strong>One-off charge emails</strong> are sent when a customer pays for a Stripe charge. You can create emails that target specific products, packages or other charge types by specifying the charge description to target in the 'advanced options' section. This is designed for subscription based charges.",
        unit: "count",
        targeting: "plans"
      },
      welcome_after: {
        id: "welcome_after",
        title: "Subscription purchased",
        html_title: 'Purchased sub<span class="trim">scription</span>',
        category: "onboarding",
        shortName: "Purchased subscription",
        description: "Send this email when a customer buys a new subscription",
        buildingDesc: "Evaluating new subscriptions",
        detailedDesc:
          "<strong>Purchased subscription</strong> emails are sent when a customer either purchases a subscription that doesn't have a trial, or successfully converts from a trial to a paying subscriber.",
        activatedDesc: "new customers <strong>welcomed</strong>",
        unit: "count",
        targeting: "plans"
      },
      trial_start_after: {
        id: "trial_start_after",
        title: "Started trial",
        category: "onboarding",
        shortName: "Started trial",
        description: "Send this email when a customer signs up for a trial",
        buildingDesc: "Evaluating new trials",
        detailedDesc:
          "<strong>Started trial emails</strong> are emails sent automatically to each customer the first time they start a subscription that has a trial.",
        unit: "count",
        targeting: "plans"
      },
      trial_will_end_before: {
        id: "trial_will_end_before",
        title: "Trial ends soon",
        category: "onboarding",
        shortName: "Trial will end",
        description:
          "Send this email a few days before a customer's trial is due to end",
        buildingDesc: "Evaluating ending trials",
        detailedDesc:
          "<strong>Trial will end soon emails</strong> are sent automatically to each customer a few days before their trial ends.",
        unit: "count",
        targeting: "plans"
      },
      trial_expired_after: {
        id: "trial_expired_after",
        category: "onboarding",
        title: "Trial expired",
        shortName: "Trial expired",
        description: "Send this email when a customer's trial expires",
        buildingDesc: "Evaluating expired trials ",
        detailedDesc:
          "<strong>Trial expired emails</strong> are sent automatically after a customer's trial has expired.",
        unit: "count",
        targeting: "plans"
      },
      renewed_after: {
        id: "renewed_after",
        title: "Subscription renewed",
        category: "lifecycle",
        shortName: "Renewal",
        description: "Send this email when a customer's subscription renews",
        buildingDesc: "Evaluating renewals",
        detailedDesc:
          "<strong>Renewal emails</strong> are sent automatically either before or after a customer's subscription ticks over onto their next billing period.",
        unit: "count",
        targeting: "plans"
      },
      renewed_before: {
        id: "renewed_before",
        title: "Subscription will renew",
        category: "lifecycle",
        shortName: "Renewal",
        description:
          "Send this email a few days before a customer's subscription renews",
        buildingDesc: "Evaluating renewals",
        detailedDesc:
          "<strong>Renewal emails</strong> are sent automatically either before or after a customer's subscription ticks over onto their next billing period.",
        unit: "count",
        targeting: "plans"
      },
      card_updated_after: {
        id: "card_updated_after",
        title: "Updated card",
        category: "payment",
        shortName: "Card updated",
        description: "Send this email when a customer's card on file changes",
        buildingDesc: "Evaluating card updates",
        detailedDesc:
          "<strong>Card updated emails</strong> are sent automatically when a customer's default card is updated either manually or automatically by the customer's bank.",
        unit: "count",
        targeting: "plans"
      },
      cancellation_recovery_after: {
        id: "cancellation_recovery_after",
        title: "Subscription cancelled",
        category: "lifecycle",
        shortName: "Cancellation",
        description:
          "Send this email when a customer cancels their subscription",
        buildingDesc: "Evaluating pending cancellations",
        detailedDesc:
          "<strong>Cancellation emails</strong> are sent automatically when a customer cancels their account. This can be a good opportunity to ask them if there's anything you can do better, or even offer them a discount if they re-subscribe.",
        activatedDesc: "<strong>rescued</strong> from cancelling subs",
        unit: "cents",
        timePeriod: "in the next 30 days",
        targeting: "plans"
      },
      upgrade_downgrade_after: {
        id: "upgrade_downgrade_after",
        title: "Changed plan",
        category: "lifecycle",
        shortName: "Upgrade/downgrade",
        description:
          "Send this email when a customer upgrades or downgrades their plan",
        buildingDesc: "Evaluating plan changes",
        detailedDesc:
          "<strong>Upgrade/downgrade</strong> emails are sent when a customer changes their subscription plan.",
        unit: "cents",
        timePeriod: "in the next 30 days",
        targeting: "plans"
      },
      subscription_will_end_before: {
        id: "subscription_will_end_before",
        title: "Subscription will not renew",
        category: "lifecycle",
        shortName: "Subscription will end",
        description:
          "Send this email a few days before a customer's subscription end",
        buildingDesc: "Evaluating renewals",
        detailedDesc:
          "<strong>Subscription will not renew emails</strong> are sent automatically a few days before a customer's cancelled subscription ends.",
        activatedDesc: "<strong>rescued</strong> from cancelling subs",
        unit: "cents",
        timePeriod: "in the next 30 days",
        targeting: "plans"
      },
      checkout_completed_after: {
        id: "checkout_completed_after",
        title: "Checkout complete",
        category: "payment",
        shortName: "Checkout complete",
        beta: true,
        description:
          "Send this email when a customer completes the purchase of a product via checkout",
        buildingDesc: "Evaluating",
        detailedDesc:
          "<strong>Checkout complete emails</strong> are sent automatically after a customer completes a checkout successfully. These can be targeted to specific products and do not require a customer to have a subscription.",
        unit: "count",
        targeting: "products"
      }
    }
  };

  opportunties.convertJob = function(jobName) {
    return opportunties.jobs[jobName] || null;
  };

  opportunties.convertType = function(type) {
    return opportunties.types[type] || type;
  };

  opportunties.list = function() {
    return opportunties.available;
  };

  opportunties.get = function(key) {
    return opportunties.available[key];
  };

  return opportunties;
};

export const $currentSender = ($resourceManager, $q) => {
  
  let currentSender = {
    sender: {email: null, name: null}
  };

  currentSender.initialize = () => {
    return $q((resolve, reject) => {
      $resourceManager.request('senders', 'current').then(response => {
        currentSender.set(response.sender);
        resolve();
      }).catch(reject);
    });
  };

  currentSender.set = sender => {
    if (sender) angular.copy(sender, currentSender.sender);
  };

  currentSender.update = sender => {
    if (!sender || !sender.email || !sender.name) return false;
    return $q((resolve, reject) => {
      $resourceManager.request('senders', 'update', {id: sender.id }, sender).then(() => {
        currentSender.set(sender);
        resolve(sender);
      }).catch(error => {
        reject(error);
      });
    });
  };

  return currentSender;

};

$currentSender.$inject = ['$resourceManager', '$q'];
export const $advancedOptions = () => {
  var triggerOptions = {
    dunning_after: {
      trigger_without_payment: {
        default: true,
        type: "boolean",
        description:
          "Also send this email if the customer has no payment details present",
      },
      trigger_on_manual_retries: {
        default: false,
        type: "boolean",
        description:
          "Also trigger this email when you attempt to collect payment manually.",
      },
      trigger_on_automatic_retries: {
        default: false,
        type: "boolean",
        description:
          "Also trigger this email every time an automatic payment retry fails.",
        link: `<a target="_blank" class='new-window-after' href="/documentation#docs-dunning-automatic-retries">Learn more</a>`,
      },
    },
    card_updated_after: {
      trigger_on_automatic_update: {
        default: false,
        type: "boolean",
        description:
          "Only send this email when the card was updated automatically",
      },
    },
    charge_succeeded_after: {
      trigger_with_description_matches: {
        default: "",
        type: "textarea",
        description:
          "Only send this email when the description matches exactly:",
        placeholder: "Example Product for 99p (leave blank to send regardless)",
      },
    },
    trial_will_end_before: {
      trigger_only_in_trial: {
        default: true,
        type: "boolean",
        description:
          "Only send this email if the customer has no payment details present",
      },
    },
    trial_start_after: {
      trigger_only_in_trial: {
        default: true,
        type: "boolean",
        description:
          "Only send this email if the customer has no payment details present",
      },
    },
    trial_expired_after: {
      trigger_without_payment: {
        default: true,
        type: "boolean",
        description:
          "Only send this email if the customer has no payment details present",
      },
      trigger_when_canceled: {
        default: false,
        type: "boolean",
        description: "Send this email when a customer transitions to canceled",
      },
    },
    invoice_receipt_after: {
      skip_first_invoice: {
        default: false,
        type: "boolean",
        description: `Don't send this email the first time the customer is invoiced.`,
        link: `<a target="_blank" href="/documentation#docs-invoice-receipt" class='new-window-after'>Why not?</a>`,
      },
    },
    welcome_after: {
      if_trial_trigger_when: {
        default: "payment_details_are_added",
        type: "select",
        description: "If upgrading from a trial, send this email",
        options: [
          {
            value: "payment_details_are_added",
            description: "when payment details are added",
          },
          {
            value: "start_of_new_billing_period",
            description: "at the start of the next billing period",
          },
        ],
      },
      ignore_subscription_canceled: {
        default: false,
        type: "boolean",
        description: "Send even if subscription state is cancelled",
      },
    },
    cancellation_recovery_after: {
      include_cancelled_trials: {
        default: false,
        type: "boolean",
        description:
          "Send this email even when a customer is cancelling from a trial.",
      },
    },
  };

  var sentFromOptions = {
    bcc_email_address: {
      default: "",
      type: "string",
      description: "Email address:",
    },
  };

  var methods = {
    getTrigger: function (type) {
      return triggerOptions[type] || false;
    },
    getSentFrom: function () {
      return sentFromOptions;
    },
  };

  return methods;
};

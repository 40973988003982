export const currencyDisplay = function (bigNumberFilter, isoCurrencyFilter) {
  return function (input, currency) {
    var withCurrency = isoCurrencyFilter(input, currency);

    withCurrency = withCurrency.replace(/,/g, '');
    var parts = withCurrency.match(/(\D+)(\d+\.?\d+)/);

    var symbol = parts[1];
    var number = parseFloat(parts[2]);
    return "<span class='currency-symbol'>" + symbol + '</span>' + bigNumberFilter(number);
  };
};

currencyDisplay.$inject = ['bigNumberFilter', 'isoCurrencyFilter'];
import "./senderSelector.scss";
import promiseMinTimeout from "../../../global/utils/promiseMinTimeout"

class _Controller {
  constructor($message, UserService, $resourceManager, $notifier, $timeout, $q, $currentSender) {
    this.$message = $message;
    this.expanded = false;
    this.$resourceManager = $resourceManager;
    this.$notifier = $notifier;
    this.$timeout = $timeout;
    this.user = UserService.user;
    this.$currentSender = $currentSender;
    this.currentSender = $currentSender.sender;
    this.tempSender = {};
    this.$q = $q;
    this.loading = true;
    this.isDirty = false;
  }

  $onInit() {
    this.getSenders().then(() => {
      this.loading = false;
      this.resetTempSender();
    });
  }

  getSenders() {
    return this.$q((resolve, reject) => {
      this.$resourceManager.request('senders', 'query', {confirmed: false}).then(response => {
        this.pendingSenders = response.senders;
        this.pendingSender = response.senders[0];
        resolve();
      }).catch(reject);
    });
  }

  showMenu(e) {
    this.expanded = true;
  }

  hideMenu() {
    this.expanded = false;
  }

  isDirtyFn() {
    return (this.tempSender.name != this.currentSender.name || this.tempSender.email !== this.currentSender.email)
  }

  onChangeName() {
    this.errorName = false;
    this.onChange();
  }

  onChangeEmail() {
    this.errorEmail = false;
    this.onChange();
  }

  onChange() {
    if (this.isDirtyFn()) {
      this.isDirty = true;
    }
  }

  resetTempSender() {
    this.tempSender = angular.copy(this.currentSender);
  }
  
  changeSender() {
    this.focusNewSenderName();
  }

  updateSender(sender) {
    if (this.tempSender.email != this.currentSender.email) {
      this.createSender(sender);
    } else {
      sender.id = this.currentSender.id;
      this.saveSender(sender);
    }
  }

  saveSentFrom() {
    const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!this.tempSender.name || !this.tempSender.email || !this.tempSender.email.match(EMAIL_REGEX)) {
      if (!this.tempSender.name) this.errorName = "required";
      if (!this.tempSender.email) {
        this.errorEmail = "required";
      } else if (!this.tempSender.email.match(EMAIL_REGEX)) {
        this.errorEmail = "needs checking";
      }
      return false;
    }
    if (this.isDirtyFn()) {
      if (this.currentSender.email && this.currentSender.name) {
        if (confirm('Changing sender details will update the sender of all your emails. Are you sure?')) this.updateSender(this.tempSender);
      } else {
        this.updateSender(this.tempSender);
      }
    } else {
      this.isDirty = false;
    }
  }

  saveSender(sender) {
    this.submitting = true;
    const promise = this.$currentSender.update(sender);
    promiseMinTimeout(promise, 500).then(() => {
      this.pendingSender = null;
      this.resetTempSender();
      this.isDirty = false;
      this.submitting = false;
    })
  }

  createSender(sender) {

    this.submitting = true;
    
    var payload = {
      email: sender.email,
      name: sender.name
    };
    
    var promise = this.$resourceManager.request('senders', 'save', {return_url: '/automations/' + this.$message.message.id }, payload);
    promiseMinTimeout(promise, 250).then(response => {
      this.pendingSender = response.sender;
      this.resetTempSender();
      this.submitting = false;
      this.isDirty = false;
    })
  }

  focusNewSenderName() {
    this.$timeout(() => {
      angular.element(document)[0].querySelector('#new-sender-name').focus();
    });
  }


}

_Controller.$inject = ['$message', 'UserService', '$resourceManager', '$notifier', '$timeout', '$q', '$currentSender'];

export const senderSelector = {
  controller: _Controller,
  template: require("./senderSelector.html"),
};
import "./asyncButton.scss"

export const asyncButton = {
  template: `
    <button type="{{($ctrl.submit) ? 'submit' : 'button'}}" class='async-standard icon-{{ $ctrl.icon }}' ng-click='($ctrl.complete) ? null : $ctrl.onClick()' ng-disabled='$ctrl.loading || $ctrl.disabled || $ctrl.complete'>
      <span class='state-initial' ng-show='!$ctrl.complete && !$ctrl.loading' ng-transclude></span>
      <span class='state-loading' ng-show='$ctrl.loading && !$ctrl.complete'>
        <span class='spinner'></span>
        <span>{{ $ctrl.loadingText || 'Processing...' }}</span>
      </span>
      <span class='state-complete' ng-show='$ctrl.complete'> {{ $ctrl.completeText || 'Done!' }}</span>
    </button>`,
  bindings: {
    onClick: '&',
    disabled: '<',
    loading: '=',
    loadingText: '@',
    complete: '<',
    completeText: '@',
    icon: '@',
    submit: '='
  },
  transclude: true,
}
import { Base64 } from 'js-base64/base64';

export const $analyticsManager = ($q, analyticQueueItem, analyticQueue) => {
  var manager = {
    queues: {}
  };

  var removeQueue = function (key) {
    // just deletes the queue from this instance
    return delete manager.queues[key];
  };

  function getQueue (key, options) {
    // get queue or create a new one
    var queue = manager.queues[key];
    if (!queue) {
      queue = analyticQueue(options);
      // remove queue once complete
      queue.onComplete(function () {
        removeQueue(key);
      });
      manager.queues[key] = queue;
    }
    return queue;
  }

  manager.generateKey = function () {
    // build a key so we can keep track of simular calls
    // eg: "{"0":{"method":"request","id":"1","type":"flows"}"
    return Base64.encode(JSON.stringify(arguments));
  
  };

  manager.request = function (options) {
    // options = {type <string>, id <id>, params <hash>, method <string> *optional*, metric <string>}

    // resolve call to be made for a given metric
    // email_deliveries for flow is a 'request'
    options = _.extend({method: 'requestHelper'}, options);

    // generate key to easly match simular requests together and queue them
    var queueKey = manager.generateKey(options);

    // use key to get analyticQueue or create one
    var currentQueue = getQueue(queueKey, options);

    // build promise for every request, so we can trigger them all at once when the first request completes
    var currentItem = analyticQueueItem(options.metric, options.type, options.id, options.params);

    // add item to currentQueue. this will send a request if its the first.
    // if not it'll wait for the first to complete then pass the result to it.
    currentQueue.add(currentItem);

    return $q(function (resolve, reject) {
      currentItem.defer.promise.then(function (queuedResponse) {
        // get required data from the response
        // return time of serverside request (for pusher)
        var response = {time: queuedResponse.time};
        response.data = (currentItem.metric) ? queuedResponse[currentItem.metric] : queuedResponse;
        resolve(response);
      }, function (reason) {
        reject(reason);
      });
    });
  };

  return manager;
};

$analyticsManager.$inject = ['$q', 'analyticQueueItem', 'analyticQueue'];

export const $availableProducts = ($resourceManager, $cableConnection) => {
  let available = {
    items: [],
    loading: false
  };

  available.initialize = () => {
    $resourceManager.request("products", "query").then(({ products }) => {
      angular.copy(products, available.items);
    });

    $cableConnection.registerFunction(message => {
      if (message.data.product) {
        if (message.data.product.done) {
          available.loading = false;
        } else {
          available.items.push(message.data.product);
        }
      }
    });
  };

  available.refresh = () => {
    available.loading = true;
    $resourceManager.request("products", "refresh");
  };

  return available;
};

$availableProducts.$inject = ["$resourceManager", "$cableConnection"];

export const $activityStore = (
  $resourceManager,
  analyticsDateFilter,
  $cableConnection
) => {
  let store = {
    activities: {
      // Gets populated by 'request' like so:
      // message-global: [],
      // message-1: [],
      // message-2: []
    }
  };

  store.add = message => {
    Object.entries(store.activities).forEach(([key, value]) => {
      if (value.request_id === message.request_id) {
        const oldStore = store.activities[key];
        const _store = {
          ...oldStore,
          loading: message.data.has_more_in_batch,
          remaining_in_db: message.data.remaining_in_db_after_batch,
          activities: [...oldStore.activities, ...message.data.activities]
        };
        angular.copy(_store, store.activities[key]);
      }
    });
  };

  store.reset = (key, period) => {
    if (key) {
      store.activities[key] = {
        loading: true,
        period: {
          start: analyticsDateFilter(period.start, "start"),
          end: analyticsDateFilter(period.end, "end")
        }
      };
    }
  };

  store.loadMore = messageId => {
    const key = `message-${messageId}`;
    const offset = store.activities[key].activities.length;
    store.request(messageId, key, offset);
  };

  store.setupAndRequest = (message, period) => {
    const messageId = message ? message.id : "global";
    const key = message ? `message-${message.id}` : "message-global";
    store.reset(key, period);
    store.request(messageId, key);
  };

  store.request = (messageId, key, offset) => {
    const params = buildRequestParams(messageId, key, offset);

    $resourceManager
      .request("messages", "activity", params)
      .then(response => {
        store.activities[key].request_id = response.request_id;
        if (!offset) store.activities[key].activities = [];
      })
      .catch(() => {
        store.activities[key].loading = false;
        store.activities[key].failed = true;
      });
  };

  $cableConnection.registerFunction(message => {
    if (message.data) {
      store.add(message);
    }
  });

  const buildRequestParams = (messageId, key, offset) => {
    const period_start = store.activities[key].period.start;
    const period_end = store.activities[key].period.end;
    const request_id = `${messageId}-${new Date().getTime()}`;
    const params = { period_start, period_end, request_id };
    if (offset) params.offset = offset;
    if (messageId != "global") params.message_id = messageId;
    return params;
  };

  return store;
};

$activityStore.$inject = [
  "$resourceManager",
  "analyticsDateFilter",
  "$cableConnection"
];

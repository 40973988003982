import "./automationFilter.scss";

class _Controller {
  constructor($opportunitiesAvailable) {
    this.$opportunitiesAvailable = $opportunitiesAvailable;
    this.expanded = false;
    this.triggerFilter = null;
  }

  showMenu(e) {
    this.expanded = true;
  }

  setFilter() {
    this.onChange({value: this.triggerFilter});
    this.hideMenu();
  }
  
  clearFilter() {
    this.triggerFilter = null;
    this.onClear();
    this.hideMenu();
  }

  hideMenu() {
    this.expanded = false;
  }

}

_Controller.$inject = ['$opportunitiesAvailable'];

export const automationFilter = {
  controller: _Controller,
  template: require("./automationFilter.html"),
  bindings: {
    onChange: '&',
    onClear: '&',
    opportunityType: '<'
  }
};
class _Controller {

  constructor() {
    this.updateCard = false;
  }
}

_Controller.$inject = [];

export const cardOverview = {
  controller: _Controller,
  template: `
    <p>
      <span ng-if='!!$ctrl.customer.invoice_settings.default_payment_method.card.last4'>
        Card number: <strong>**** **** **** {{ $ctrl.customer.invoice_settings.default_payment_method.card.last4 }}</strong><br>
        Expiry date: <strong>
          {{ ($ctrl.customer.invoice_settings.default_payment_method.card.exp_month).toString().padStart(2, "0"); }}/{{ ($ctrl.customer.invoice_settings.default_payment_method.card.exp_year).toString(); }}
        </strong>
      </span>
      <p><a ng-click='$ctrl.updateCard = true' ng-show='!$ctrl.updateCard'>Charge a different card...</a></p>
    </p>
    <update-card ng-show='$ctrl.updateCard'></update-card>
    `,
  bindings: {
    customer: '<',
  }
};



class _Controller {

  constructor($timeout) {
    this.$timeout = $timeout;
    this.loaded = false;
  }

  $onInit() {
    this.$timeout(() => {
      this.loaded = true;
    }, 100);
  }

}

_Controller.$inject = ['$timeout'];

export const accountSettings = {
  controller: _Controller,
  template: require('./accountSettings.html')
};
